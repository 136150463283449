<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field grouped>
        <p class="title is-5">Personal Details</p>
      </b-field>

      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="Personal"
        @clearFields="clear()"
        v-if="consented"
      ></the-nav-buttons>
      <form @submit.prevent="submitPersonalDetails" v-if="consented" style="padding-top: 15px">
        <b-field grouped group-multiline style="padding-bottom:15px;">
          <div class="block" style="padding-right:15px">
            <p>
              <b>Do you consent to CareWorks processing your personal information for the specific purpose of assessing
              your health risk?</b>
            </p>
          </div>

          <b-field>
            <b-radio v-model="processingConsent" name="process" native-value="yes" required>
              yes
            </b-radio>
            <b-radio @input="showFailedConsent()" v-model="processingConsent" name="process" native-value="no" required>
              no
            </b-radio>
          </b-field>
        </b-field>
        <div @keydown.enter.stop>
          <div class="columns is-mobile is-variable is-5-desktop">
            <div class="column">
              <b-field label="First name">
                <b-input
                  v-model.trim="userDetails.name"
                  maxlength="20"
                  type="text"
                  size="is-small"
                  required
                  validation-message="required field"
                ></b-input
              ></b-field>
            </div>
            <div class="column">
              <b-field label="Last name">
                <b-input
                  v-model.trim="userDetails.surname"
                  maxlength="20"
                  type="text"
                  size="is-small"
                  required
                  validation-message="required field"
                ></b-input
              ></b-field>
            </div>
          </div>

          <div class="columns is-mobile is-variable is-5-desktop">
            <div class="column">
              <b-field
                :type="successfulCellCapture"
                label="Cellphone number"
                message="This is the number we will contact you on if given consent."
                expanded
                ><b-input
                  v-model.trim="userDetails.cellphone"
                  type="number"
                  maxlength="10"
                  size="is-small"
                  required
                  validation-message="required field"
                ></b-input
              ></b-field>
            </div>
            <div class="column">
              <b-field label="Email Address" expanded :type="successfulEmailCapture">
                <b-input v-model.trim="userDetails.emailAddress" size="is-small"></b-input
              ></b-field>
            </div>
          </div>

          <div class="columns is-mobile is-variable is-5-desktop">
            <div class="column">
              <b-field
                label="Campaign code"
                message="Please contact your HR manager if you don't know this code"
                expanded
              >
                <b-input
                  v-model.trim="userDetails.campaignCode"
                  type="text"
                  size="is-small"
                  required
                  validation-message="required field"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="ID Number">
                <b-input
                  v-model.trim="userDetails.idNumber"
                  type="text"
                  size="is-small"
                  required
                  validation-message="required field"
                ></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns is-mobile is-variable is-5-desktop">
            <div class="column">
              <b-field label="Date of birth" :type="showDateSelectionTag ? 'is-danger' : ''" expanded>
                <b-input readonly :value="dateString" size="is-small" />
                <b-datepicker
                  v-model="userDetails.dateOfBirth"
                  :mobile-native="false"
                  :max-date="maxDate"
                  position="is-top-right"
                  required
                >
                  <template v-slot:trigger>
                    <b-button icon-right="calendar" type="is-primary" size="is-small"></b-button>
                  </template>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Sex" expanded>
                <b-radio v-model="userDetails.gender" name="gender" native-value="male" required>
                  male
                </b-radio>
                <b-radio v-model="userDetails.gender" name="gender" native-value="female" required>
                  female
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Member Number">
                <b-input v-model="userDetails.memberNumber" size="is-small" required></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Dependent Code">
                <b-input v-model="userDetails.dependentCode" :type="successfulDependentCode" size="is-small" required></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column"></div>
            <div class="column"></div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Do you consent to being contacted by a CareWorks counsellor?" expanded required>
                <b-radio v-model="userDetails.contactConsent" name="consent" native-value="yes">
                  yes
                </b-radio>
                <b-radio v-model="userDetails.contactConsent" name="consent" native-value="no">
                  no
                </b-radio>
              </b-field>
            </div>
            <div class="column"></div>
          </div>

          <b-field style="padding-top:10px">
            <b-button @keydown.enter.prevent="noop" type="is-primary" outlined native-type="submit">submit</b-button>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

            <!-- <b-notification v-if="!consented"
            type="is-secondary"
            aria-close-label="Close notification"
            role="alert">
            Without your contact consent, we cannot coninute this assessment. Thank you for your time.
        </b-notification> -->
          </b-field>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
import { mapGetters } from "vuex";
import { QueryService } from "../../services/query.service.js";
import { LocalStorage } from "../../services/storage.service.js";
import { ToastProgrammatic as toast } from "buefy";
import smsService from "../../services/sms.service";

export default {
  components: {
    NavBar,
    TheNavButtons,
  },
  data() {
    const today = new Date();
    return {
      processingConsent: null,
      consented: true,
      formCompleted: false,
      formValid: false,
      dateSelected: false,
      showDateSelectionTag: false,
      isLoading: false,
      bmi: 0.0,
      nextScreen: "",
      campaign: null,
      userDetails: {
        referenceNum: "", //uniquely generated number for querying database and identifying connected records
        name: "",
        surname: "",
        fullName: "",
        idNumber: "",
        cellphone: "",
        emailAddress: "",
        dateOfCompletion: null, //computed when form submitted
        campaignCode: "Bon01",
        dateOfBirth: null,
        dobString: "", // dd/mm/yyyy string of dateOfBirth Date() object
        age: null,
        ageBand: "",
        gender: "female",
        fieldsCompleted: false,
        contactConsent: "yes",
        pharmacyConsent: "yes",
        dependentCode: "",
        memberNumber: "",
        cardioData: null,
        hypertensionData: null,
        cancerData: null,
        hivData: null,
        tbData: null,
        stiData: null,
      },

      maxDate: new Date(
        today.getFullYear() - 10, //assuming some minimum age of 10?
        today.getMonth(),
        today.getDate()
      ),
      visibleSections: {
        cardio: false,
        hypertension: false,
        numbers: false,
        diabetes: false,
        hiv: false,
        cancer: false,
        adhoc: false,
        mentalHealth: false,
        femaleHealth: false,
      },
    };
  },
  methods: {
    ...mapGetters(["getName", "getUserObject", "getFormCompletion", "getFormValidity", "getSaveCompletion"]),

    async submitPersonalDetails() {
      this.isLoading = true;
      await this.validateFields();

      if (this.formCompleted == true) {
        this.bmi = this.computeBMI;
        this.userDetails.name = this.capitalise(this.userDetails.name);
        this.userDetails.surname = this.capitalise(this.userDetails.surname);
        this.userDetails.fullName = this.computeFullName;
        this.userDetails.dateOfCompletion = this.completionDate;
        this.userDetails.dobString = this.dateString;
        this.userDetails.age = Number(this.computeAge);
        this.userDetails.ageBand = this.computeAgeBand;
        this.userDetails.referenceNum = this.computeRefNum;

        if (this.userDetails.age < 50 && this.userDetails.gender == "male") {
          this.$store.dispatch("setCancerFinished", true);
        }

        this.$store.dispatch("setUserDetails", this.userDetails); //dispatch an action
        this.$store.dispatch("setFormCompletion", this.formCompleted);

        await this.saveUserToDB(this.userDetails);
      }
    },
    async validateFields() {
      //used to validate certain fields which cannot be controlled with built-in limitations
      var valid = true;
      try {
        let res = await QueryService.get_campaign(this.userDetails.campaignCode);
        var campaign = JSON.parse(res.data.campaign);
        this.campaign = campaign;
        this.$store.dispatch("saveCampaign", campaign);
        this.setVisibleSections();
      } catch (error) {
        valid = false;
        this.isLoading = false;
        if (error.response) {
          this.errorToast(error.response.data.message, 4000);
        } else {
          this.errorToast("Failed to fetch campaign");
        }
      }

      if (this.userDetails.dateOfBirth != null) {
        this.dateSelected = true;
        this.showDateSelectionTag = false;
      } else {
        this.showDateSelectionTag = true; //data has not been selected
        valid = false;
      }
      if (
        this.userDetails.cellphone.length != 10
      ) {
        valid = false;
        this.$buefy.toast.open({
          duration: 4000,
          message: "All contact numbers should be 10 digits long.",
          type: "is-danger",
        });
      }
      if (
        this.userDetails.cellphone.indexOf(".") != -1 ||
        this.userDetails.cellphone.indexOf("e") != -1 ||
        this.userDetails.cellphone.indexOf(",") != -1
      ) {
        //-1 indicates it was not found, also weirdly the 'e' char doesn't seemed to be blocked
        valid = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Contact numbers cannot contain decimal points or letters.",
          type: "is-danger",
        });
      }

      if (valid == true) {
        this.formCompleted = true;
      } else {
        this.formCompleted = false;
        this.isLoading = false;
      }
    },
    capitalise(word) {
      //make first letter capital
      if (word.length != 0) {
        return word.replace(word[0], word[0].toUpperCase());
      } else {
        return word;
      }
    },
    convertDateObject(date) {
      const dateStrings = [];
      if (date != null) {
        var dd = String(date.getDate()).padStart(2, "0");
        var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = date.getFullYear();

        dateStrings.push(dd);
        dateStrings.push(mm);
        dateStrings.push(yyyy);
      }
      return dateStrings;
    },
    loadUserDetails(refNum = null) {
      this.isLoading = true;
      QueryService.get_UserDetails(refNum)
        .then((response) => {
          //state.userDetails = response.data;
          this.successToast("Successfully loaded user details", 2000);

          const obj = this.mapJsonFields(response.data[0]);
          this.userDetails = obj;
          obj.cardioData.strokeOrHeartAttack = "yes";
          obj.cardioData.highCholestrol = "yes";

          this.$store.dispatch("saveCardioData", obj.cardioData);
        })
        .catch((response) => {
          console.log(response);
          this.errorToast("Error retreiving user details from the database.", 4000);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveUserToDB(userObj) {
      this.isLoading = true;
      //this.formValid = false;
      QueryService.save_UserDetails(userObj)
        .then((res) => {
          toast.open({
            duration: 2000,
            message: "user saved to database successfully",
            position: "is-bottom-right",
            type: "is-success",
            hasIcon: false,
          });
          this.isLoading = false;
          this.formValid = true;

          this.$store.dispatch("setRefNum", res.data.refNum); //save new ref num for reference when updating object
          if (this.formValid == true && this.consented == true) {
            this.$store.dispatch("setFormValidity", this.formValid);
            const messages = [
              {
                Content:
                  "Congratulations, you have registered for the CareWorks Self Health Evaluation. If you have any further queries, you may contact CareWorks on 0800 243 671",
                Destination: String(this.userDetails.cellphone),
              },
            ];
            smsService.sendSMS(messages);
            this.$router.push({ name: "Cardio" });
          }
        })
        .catch((error) => {
          toast.open({
            duration: 4000,
            message: error.response.data.message,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: false,
          });
          console.log(error);
          this.isLoading = false;
          // this.formValid = false;
        });
    },
    setVisibleSections() {
      var secs = this.campaign.assessmentSections;

      if (secs != null) {
        for (var i = 0; i < secs.length; i++) {
          if (secs[i] == "Cardio") {
            this.visibleSections.cardio = true;
          }
          if (secs[i] == "Hypertension") {
            this.visibleSections.hypertension = true;
          }
          if (secs[i] == "Health Check") {
            this.visibleSections.numbers = true;
          }
          if (secs[i] == "Diabetes") {
            this.visibleSections.diabetes = true;
          }
          if (secs[i] == "Cancer") {
            this.visibleSections.cancer = true;
          }
          if (secs[i] == "HIV/TB/STI") {
            this.visibleSections.hiv = true;
          }
          if (secs[i] == "Mental Health") {
            this.visibleSections.mentalHealth = true;
          }
          if (secs[i] == "Female Health") {
            this.visibleSections.femaleHealth = true;
          }
          if (secs[i] == "Campaign Specific") {
            this.visibleSections.adhoc = true;
          }
        }
        this.$store.dispatch("setVisibleSections", this.visibleSections);
      }
    },
    clear() {
      this.userDetails = {
        referenceNum: "", //uniquely generated number for querying database and identifying connected records
        name: "",
        surname: "",
        fullName: "",
        idNumber: "",
        cellphone: "",
        emailAddress: "",
        dateOfCompletion: null, //computed when form submitted
        campaignCode: "",
        dateOfBirth: null,
        dobString: "", // dd/mm/yyyy string of dateOfBirth Date() object
        age: null,
        ageBand: "",
        gender: null,
        fieldsCompleted: false,
        contactConsent: "yes",
        pharmacyConsent: "yes",
        completedBy: "",
        cardioData: null,
        hypertensionData: null,
        cancerData: null,
        cervicalCancerData: null,
        prostateCancerData: null,
        breastCancerData: null,
        colorectalCancerData: null,
        hivData: null,
        tbData: null,
        stiData: null,
        numbers: null,
        adhoc: null,
      };
    },
    exitAssessment() {
      this.clear();
      this.$router.push({ name: "Home" });
    },
    showFailedConsent() {
      this.$buefy.dialog.confirm({
        title: "Consent",
        message:
          "Please note that this questionnaire is a risk assessment that should be followed up by a discussion with your healthcare provider. You may contact CareWorks on 0800 243 671 if you would like to discuss this questionnaire with a CareWorks nurse directly.",
        confirmText: "Exit Assessment",
        type: "is-secondary",
        hasIcon: true,
        onCancel: () => {
          this.processingConsent = "yes";
        },
        onConfirm: () => {
          LocalStorage.remove("userDetails");
          this.$router.push({ name: "Home" });
        },
      });
    },
  },
  computed: {
    computeBMI() {
      return (this.userDetails.weight / (this.userDetails.height * this.userDetails.height)).toFixed(1); //kg/m^2
    },
    computeRefNum() {
      return this.userDetails.name[0] + this.userDetails.surname[0] + "001"; //REPLACE 001 with length of DB entries
    },
    computeAgeBand() {
      const age = this.userDetails.age;
      if (age < 20) return "<20";
      if (age >= 60) return "60+";
      var closestTen = Math.round(age / 10) * 10; //returns say 30 if age=33 or 40 if age=37
      if (age < closestTen) {
        //then upper band within interval
        return closestTen - 5 + "-" + (closestTen - 1);
      } else {
        return closestTen + "-" + (closestTen + 4);
      }
    },

    computeAge() {
      var today = new Date();
      var dob = this.userDetails.dateOfBirth;

      const dstring = this.convertDateObject(dob);
      const dd = dstring[0];
      const mm = dstring[1];
      const yyyy = dstring[2];

      const dstring_now = this.convertDateObject(today);
      var dd_now = dstring_now[0];
      var mm_now = dstring_now[1];
      var yyyy_now = dstring_now[2];

      var age = yyyy_now - yyyy; //returns age if birthday has passed in current year
      if (mm_now < mm) age -= 1;
      //before birthday month
      else if (mm_now == mm && dd_now < dd) age -= 1; //before birthday, but in birthday month
      //console.log('AGE', age)
      return age;
    },
    dateString() {
      var dob = this.userDetails.dateOfBirth;
      if (dob == null) {
        return "select date";
      } else {
        console.log(dob);
        const dstring = this.convertDateObject(dob);
        return dstring[0] + "/" + dstring[1] + "/" + dstring[2];
      }
    },
    successfulEmailCapture() {
      const email = this.userDetails.emailAddress;
      if (email.length == 0) {
        return "";
      } else if (email.includes("@") && email.includes(".")) {
        return "is-success";
      } else {
        return "is-danger";
      }
    },

    successfulDependentCode(){
      return ""
    },

    successfulWorkCapture() {
      const numLength = this.userDetails.workNumber.length;
      if (numLength == 0) {
        return "";
      } else if (numLength == 10 && numLength > 0) {
        return "is-success";
      } else {
        return "is-danger";
      }
    },
    successfulCellCapture() {
      const numLength = this.userDetails.cellphone.length;
      if (numLength == 0) {
        return "";
      } else if (numLength == 10 && numLength > 0) {
        return "is-success";
      } else {
        return "is-danger";
      }
    },
    computeFullName() {
      return this.userDetails.name + " " + this.userDetails.surname;
    },
    completionDate() {
      var today = new Date(); //returns todays date
      // var dstring = this.convertDateObject(today);
      // return dstring[0] + "/" + dstring[1] + "/" + dstring[2];
      return today;
    },
  },

  watch: {
    dateString() {
      this.userDetails.age = Number(this.computeAge);
    },
    formValid() {},
    "userDetails.idNumber": function(val) {
      if (val.length >= 6) {
        let id = String(val);
        let d = id.substring(0, 2) + "/" + id.substring(2, 4) + "/" + id.substring(4, 6);
        try {
          d = new Date(d);
          this.userDetails.dateOfBirth = d;
        } catch {
          console.log("failed to parse date");
        }
      }
    },
  },

  created() {
    //fetch stored data upon component creation
    const userObject = this.getUserObject();
    const storedObject = LocalStorage.get("userDetails");

    if (userObject != null) {
      this.userDetails = userObject;
    } else {
      if (storedObject != null) {
        //this.userDetails = storedObject;
      }
    }
    this.formCompleted = this.getFormCompletion;
    this.formValid = this.$store.getters.getFormValidity;
    console.log("form validity: ", this.formValid);
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    if (to.name == "Home") {
      if (this.processingConsent != "no") {
        this.$buefy.dialog.confirm({
          title: "Exiting",
          message: "Are you sure you want to exit the  Self Health Evaluation? All of your progress will be lost.",
          confirmText: "Exit",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            LocalStorage.remove("userDetails");
            LocalStorage.remove("userDetails");
            this.$store.dispatch("clearUserDetails");
            this.$store.dispatch("clearCampaign");
            this.$store.dispatch("clearCancer");
            this.$store.dispatch("clearCardio");
            this.$store.dispatch("clearHypertension");
            this.$store.dispatch("clearNumbers");
            this.$store.dispatch("clearHIV");
            this.$store.dispatch("clearDiabetes");
            this.$store.dispatch("clearMentalHealth");
            this.$store.dispatch("clearFemaleHealth");
            this.$store.dispatch("setFormValidity", false);
            next();
          },
        });
      } else {
        next(); //if processing = no, leave
      }
    } else {
      if (this.formValid) {
        console.log("storing..");
        this.$store.dispatch("setUserDetails", this.userDetails); //save details upon leaving page
        next();
      } else {
        this.errorToast("complete this section before continuing", 1500);
      }
    } // end if form valid
  },
};
</script>
