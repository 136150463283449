import { ApiService } from "./api.service";

const QueryService = {
  get_ActionMessage: async function(section, score, riskCat) {
    let res = await ApiService.get("/data/action_message", {
      params: { assessment_section: section, score: score, risk_cat: riskCat },
    });
    return res;
  },
  get_RiskMessages: async function(section, riskCat) {
    let res = await ApiService.get("/data/risk_messages", {
      params: { assessment_section: section, risk_cat: riskCat },
    });
    return res;
  },
  update_RiskMessage: async function(riskObj) {
    let res = await ApiService.post("/update/risk_messages", riskObj);
    return res;
  },
  save_RiskMessage: async function(riskObj) {
    let res = await ApiService.post("/save_risk_message", riskObj);
    return res;
  },
  get_UserDetails: async function(idNum, campaignCode) {
    let res = await ApiService.get("/data/users", {
      params: { id_number: idNum, campaign_code: campaignCode },
    });
    return res;
  },

  save_UserDetails: async function(userObj) {
    let res = await ApiService.post("/save_record", userObj);
    return res;
  },
  update_withNumbers: async function(numbersObj) {
    let res = await ApiService.post("/update/numbers", numbersObj);
    return res;
  },
  update_withCardio: async function(cardioObj) {
    let res = await ApiService.post("/update/cardio", cardioObj);
    return res;
  },
  update_withMentalHealth: async function(mentalHealthObj) {
    let res = await ApiService.post("/update/mentalhealth", mentalHealthObj);
    return res;
  },
  update_withFemaleHealth: async function(femaleHealthObj) {
    let res = await ApiService.post("/update/femalehealth", femaleHealthObj);
    return res;
  },
  update_withHypertension: async function(hypObj) {
    let res = await ApiService.post("/update/hypertension", hypObj);
    return res;
  },
  update_withDiabetes: async function(diabObj) {
    let res = await ApiService.post("/update/diabetes", diabObj);
    return res;
  },
  update_withCancer: async function(cancerObj) {
    let res = await ApiService.post("/update/cancer", cancerObj);
    return res;
  },
  update_withHIV: async function(hivObj) {
    let res = await ApiService.post("/update/hiv", hivObj);
    return res;
  },
  update_withTB: async function(tbObj) {
    let res = await ApiService.post("/update/tb", tbObj);
    return res;
  },
  update_withSTI: async function(stiObj) {
    let res = await ApiService.post("/update/sti", stiObj);
    return res;
  },

  update_withAdhoc: async function(adhocQuestions, refNum) {
    let res = await ApiService.post("/update/adhoc",{ "adhoc": adhocQuestions, "ref":refNum});
    return res;
  },
  save_Campaign: async function(campaignObj) {
    let res = await ApiService.post("/save/campaign", campaignObj);
    return res;
  },
  get_campaignNumbers: async function() {
    let res = await ApiService.get("/data/campaignNumbers");
    return res;
  },

  get_campaign: async function(code) {
    let res = await ApiService.get("/data/campaign", {
      params: { campaignNumber: code },
    });
    return res;
  },
  update_Campaign: async function(campaign){
    let res = await ApiService.post("/update/campaign", campaign)
    return res;
  },

  update_Questions: async function(questions){
    let res = await ApiService.post("/update/questions", questions)
    return res;
  },

  save_Questions: async function(questions) {
    //takes an array of question objects
    let res = await ApiService.post("/save/questions", questions);
    return res;
  },
  get_Questions: async function(qIds) {
    let res = await ApiService.get("/data/questions", {
      params: { questionIds: qIds },
    });
    return res;
  },
  get_CSV: async function(records){
    let res = await ApiService.post("/data/csv", records);
    return res
  }
};

export { QueryService };
