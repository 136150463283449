<template>
  <div id="" class="">
    <!--top, right, bottom, left-->
   
      <img
        class=""
        alt="careworks logo"
        style="width: 60%; height: 60%;"
        src="../../assets/bonitas.svg"
      />
   
  </div>
</template>

<script>
export default {
  
};
</script>
<style scoped>
.crop {
  margin-top: -30px;
  margin-right: -30px;
}

#logo {
  position: fixed;
  left: 0;
  top: 0;
}
</style>
