<template>
  <section>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field>
        <p class="title is-5">Hypertension</p>
      </b-field>
      <b-field
        ><p id="info" @click="showHypInfo()">
          {{ hypInfo }}
        </p></b-field
      >
      <b-field style="color:$primary; width: device-width" v-if="hypInfoVisible"
        ><p>{{ hypInfoText }}</p></b-field
      >
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="Hypertension"
        @clearFields="clear()"
      ></the-nav-buttons>
      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to complete this section of this assessment
      </b-message>
      <form
        @submit.prevent="submitHypertensionData()"
        style="padding-top: 15px"
        v-if="visible"
      >
        <!--QUESTION 1-->
        <div class="columns customQuestion">
          <div class="column is-three-quarters">
            <b-field expanded
              ><p>
                1. Have you ever been diagnosed with hypertension (high blood
                pressure)?
              </p></b-field
            >
          </div>
          <div class="column">
            <b-field position="is-centered">
              <b-radio
                v-model="hypertensionData.diagnosed"
                name="bpressure"
                native-value="yes"
                required
                >yes</b-radio
              >
              <b-radio
                v-model="hypertensionData.diagnosed"
                name="bpressure"
                native-value="no"
                required
                >no</b-radio
              >
            </b-field>
          </div>
        </div>

        <question
          :index="2"
          v-if="hypertensionData.diagnosed == 'yes'"
          question="Are you on medication for hypertension?"
          radioName="meds"
          @answered="getAnswer"
          :value="hypertensionData.onMedication"
          type="yes/no"
        ></question>

        <div
          class="columns customQuestion"
          v-if="hypertensionData.diagnosed == 'no' || hypertensionData.diagnosed == null"
        >
          <div class="column is-three-quarters">
            <b-field expanded
              ><p>
                2. Have you ever been told that your blood pressure is high?
              </p></b-field
            >
          </div>
          <div class="column">
            <b-field position="is-centered">
              <b-radio
                v-model="hypertensionData.toldYouHaveHyptertension"
                name="told"
                native-value="yes"
                required
                >yes</b-radio
              >
              <b-radio
                v-model="hypertensionData.toldYouHaveHyptertension"
                name="told"
                native-value="no"
                required
                >no</b-radio
              >
            </b-field>
          </div>
        </div>

        <!--QUESTION 3-->
        <div class="columns customQuestion">
          <div class="column is-three-quarters">
            <b-field expanded
              ><p>3. Do you have a family history of hypertension?</p></b-field
            >
          </div>
          <div class="column">
            <b-field position="is-centered">
              <b-radio
                v-model="hypertensionData.familyHistory"
                name="history"
                native-value="yes"
                required
                >yes</b-radio
              >
              <b-radio
                v-model="hypertensionData.familyHistory"
                name="history"
                native-value="no"
                required
                >no</b-radio
              >
            </b-field>
          </div>
        </div>

        <!--QUESTION 4-->
        <div class="columns customQuestion" v-if="gender == 'female'">
          <div class="column is-three-quarters">
          
            <b-field expanded
              ><p>
                4. If you have ever been pregnant before, have you ever been
                diagnosed with hypertension during pregnancy?
              </p></b-field
            >
          </div>
          <div class="column">
            <b-field position="is-centered">
              <b-radio
                v-model="hypertensionData.duringPregnancy"
                name="pregnancy"
                native-value="yes"
                required
                >yes</b-radio
              >
              <b-radio
                v-model="hypertensionData.duringPregnancy"
                name="pregnancy"
                native-value="no"
                required
                >no</b-radio
              >
            </b-field>
          </div>
        </div>

        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </section>
</template>

<script>
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import Question from "../ui/Question.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    Question,
    TheNavButtons,
  },
  data() {
    return {
      hypInfo: "more information",
      hypInfoText:
        "Anyone can develop high blood pressure as so many risk factors are lifestyle related.  You are also more likely to be told your blood pressure is too high as you get older and your arteries stiffen. Your risk of high blood pressure increases if you have a family history of high blood pressure, are obese, have diabetes, smoke, are often stressed or anxious, and/or eat too much salt in your diet.",
      hypInfoVisible: false,
      visible: true,
      age: null,
      gender: null,
      isLoading: false,
      continue: false,
      hypertensionData: {
        referenceNum: "",
        diagnosed: "",
        onMedication: "",
        toldYouHaveHyptertension: "",
        familyHistory: "",
        duringPregnancy: "",
        hypertensionScore: null,
        riskCat: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
    };
  },
  methods: {
    clear() {
      this.hypertensionData = {
        referenceNum: "",
        diagnosed: "",
        onMedication: "",
        toldYouHaveHyptertension: "",
        familyHistory: "",
        duringPregnancy: "",
        hypertensionScore: null,
        riskCat: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      };
    },
    showHypInfo() {
      this.hypInfoVisible = !this.hypInfoVisible;
      if (this.hypInfoVisible) {
        this.hypInfo = "hide";
      } else {
        this.hypInfo = "more information";
      }
    },
    async submitHypertensionData() {
      this.hypertensionData.referenceNum = this.$store.getters.getRefNum;
      await this.computeRisk();
      this.$store.dispatch("saveHypertensionData", this.hypertensionData);
      this.saveHypertensionToDB(this.hypertensionData);
    },
    saveHypertensionToDB(obj) {
      this.isLoading = true;
      QueryService.update_withHypertension(obj)
        .then((res) => {
          this.successToast(res.data.message, 2000);
          console.log(res);
          this.isLoading = false;
          this.continue = true;
          this.$store.dispatch("setHypertensionFinished", true);
        })
        .catch((error) => {
          this.errorToast("error saving hypertension data", 4000);
          console.log(error);
          this.isLoading = false;
        });
    },
    async computeRisk() {
      var score = 0;
      if (this.hypertensionData.diagnosed == "no") {
        if (this.hypertensionData.toldYouHaveHyptertension == "yes") score++;
        if (this.hypertensionData.familyHistory == "yes") score++;
        if (
          this.hypertensionData.duringPregnancy == "yes" &&
          this.gender == "female"
        ) {
          score += 4;
        }
        switch (this.gender) {
          case "male":
            score += 1;
            if (this.age >= 45) {
              score += 1;
            }
            break;
          case "female":
            score += 1;
            if (this.age >= 65) {
              score += 1;
            }
            break;
          default:
            score += 1; //just add one for male or female, auth should not allow user to get to this point without entering age and gender
        } //end switch statement
      } //end if
      else {
        //i.e. has been diagnosed with hypertension
        if (this.hypertensionData.onMedication == "no") {
          score += 4;
        }
      }

      if (score == 4) this.hypertensionData.riskCat = "moderate risk";
      else if (score < 4) this.hypertensionData.riskCat = "low risk";
      else this.hypertensionData.riskCat = "high risk";
      this.hypertensionData.hypertensionScore = score;
      console.log(this.hypertensionData.riskCat, score);
      let res = "";
      if (score >= 5) {
        res = await this.getActionMessage(">=5", this.hypertensionData.riskCat);
      } else if (score == 4) {
        res = await this.getActionMessage("==4", this.hypertensionData.riskCat);
      } else {
        res = await this.getActionMessage("<4", this.hypertensionData.riskCat);
      }
      return res;
    },

    getAnswer(payload) {
      //get returned value from question component
      this.hypertensionData.onMedication = payload;
    },
    getActionMessage(score, cat) {
      let resp = QueryService.get_ActionMessage("hypertension", score, cat)
        .then((res) => {
          console.log(res);
          if (res.data.length == 0) {
            console.log("Action message empty.");
          } else {
            if (this.gender == "male")
              this.hypertensionData.message = res.data[0].message_male;
            else this.hypertensionData.message = res.data[0].message_female;
            this.hypertensionData.nurse_task = res.data[0].nurse_task;
            this.hypertensionData.screening_type = res.data[0].screening_type;
            if (res.data[0].notes != "")
              this.hypertensionData.notes = res.data[0].notes;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return resp;
    },
  },
  watch: {
    continue() {
      if (this.continue == true) {
        this.continue = false; //change back to false so that we only continue after submitting form
        this.$router.push({ name: "Diabetes" });
      }
    },
  },

  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.hypertension;
    }
    this.age = this.$store.getters.getAge;
    this.gender = this.$store.getters.getGender;
    const hypObject = this.$store.getters.getHypertensionData;
    if (hypObject) {
      this.hypertensionData = hypObject;
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.dispatch("saveHypertensionData", this.hypertensionData); //save details upon leaving page
    next();
  },
};
</script>

<style scoped>
.customQuestion {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
