<template>
  <div class="columns" style="margin-bottom: 20px">
    <div class="column">
      <p class="title is-6" style="margin-bottom: 3px">
        {{ title }}
      </p>
      <p style="font-size: 13px" class="riskText">
        {{ capitalise }}
      </p>
    </div>
    <div class="column is-three-quarters">
      <p style="font-size: 13px">
        Recommendations:<br />
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    riskCat: String,
    message: String, //for binding
  },
  computed: {
    capitalise() {
      return this.riskCat[0].toUpperCase() + this.riskCat.substring(1);
    },
  },
};
</script>

<style lang="scss" scoped >
@import "../../../custom-styles.scss";
p {
  white-space: pre-wrap;
}
.riskText {
  color: $primary;
}
</style>
