<template>
  <section>
    <div class="has-text-left container" style="padding: 0px 0px 100px 0px;">
      <b-field grouped>
        <b-field label="Category">
          <b-select size="is-small" v-model="category">
            <option v-for="option in categories" :key="option">{{
              option
            }}</option>
          </b-select>
        </b-field>

        <b-field label="Risk Cat">
          <b-select size="is-small" v-model="riskCat">
            <option v-for="cat in riskCats" :key="cat">{{ cat }}</option>
          </b-select>
        </b-field>

        <b-button
          type="is-primary"
          size="is-small"
          style="margin-top:30px;"
          @click="getRiskMessages()"
          outlined
          rounded
          >query</b-button
        >
        <b-button
          type="is-primary"
          size="is-small"
          style="margin-top:30px; margin-left:10px;"
          @click="clear()"
          outlined
          rounded
          >clear selection</b-button
        >
        <b-button
          type="is-primary"
          size="is-small"
          style="margin-top:30px; margin-left:10px;"
          @click="createNew()"
          outlined
          rounded
          icon-left="plus"
          >new</b-button
        >
      </b-field>
      <div v-if="create">
        <risk-cat-template
          :index="0"
          @changed="save"
          score=""
          riskCat=""
          nurseTask=""
          messageMale=""
          messageFemale=""
          smsMessage=""
          screeningType=""
          buttonText="save"
        ></risk-cat-template>
      </div>

      <div v-if="riskMessages.length > 0">
        <risk-cat-template
          v-for="(message, index) in riskMessages"
          :key="message._id.$oid"
          :edited="edited"
          :index="index"
          :score="message.score"
          :riskCat="message.risk_cat"
          :nurseTask="message.nurse_task"
          :messageMale="message.message_male"
          :messageFemale="message.message_female"
          :smsMessage="message.sms_message"
          :screeningType="message.screening_type"
          buttonText="update"
          @changed="update"
        ></risk-cat-template>
      </div>

      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </section>
</template>

<script>
import { QueryService } from "../../services/query.service";
import RiskCatTemplate from "./RiskCatTemplate.vue";
export default {
  components: { RiskCatTemplate },

  data() {
    return {
      idNumber: "",
      campaign: "",
      isLoading: false,
      edited: false,
      create: false,
      categories: [
        "cardio",
        "hypertension",
        "diabetes",
        "cancer",
        "cervical cancer",
        "prostate cancer",
        "colorectal cancer",
        "breast cancer",
        "hiv",
        "tb",
        "sti",
        "blood pressure",
        "glucose",
        "cholesterol",
        "hb1ac",
        "bmi",
        "mental health",
        "female health"
      ],
      riskCats: [
        "high risk",
        "low risk",
        "moderate risk",
        "low to moderate risk",
        "pre diabetic",
        "diabetic",
        "controlled",
        "uncontrolled",
        "general",
        "not pregnant",
        "trying for first pregnancy",
        "trying to fall pregnant again",
        "first pregnancy",
        "subsequent pregnancy"
      ],
      riskMessages: [],
      category: null,
      riskCat: null,
    };
  },
  methods: {
    async update(actionMessage) {
      this.edited = false;
      actionMessage["assessment_section"] = this.category;
      actionMessage["notes"] = "";
      let res = await QueryService.update_RiskMessage(actionMessage);
      if (res.data == "update successful") {
        this.successToast(res.data, 2000);
      } else {
        this.errorToast(res.data, 4000);
      }
    },
    clear() {
      this.riskMessages = [];
      this.create=false;
      this.category = null;
      this.riskCat = null;
    },
    async getRiskMessages() {
      this.isLoading = true;
      let res = await QueryService.get_RiskMessages(
        this.category,
        this.riskCat
      );
      this.isLoading = false;
      if (res.status == 200) {
        this.create = false;
        this.riskMessages = res.data;
      } else {
        console.log(res);
      }
    },

    createNew() {
      if (this.category != null) {
        this.create = true;
        //this.riskMessages = [];
      } else {
        this.errorToast("Please select a category", 2000);
      }
    },
    async save(actionMessage) {
      actionMessage["assessment_section"] = this.category;
      actionMessage["notes"] = "";
      console.log(actionMessage);
      let res = await QueryService.save_RiskMessage(actionMessage);
      if (res.status == 200) {
        this.successToast(res.data, 2000);
        this.getRiskMessages();
        this.create = false;
      } else {
        this.errorToast(res.data, 4000);
        console.log(res);
      }
    },

    exitAdminPortal() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
