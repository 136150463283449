<template>
  <div>
    <div class="container" id="ignore">
      <div class="columns no-print" style="padding-top: 50px">
        <div class="column is-one-fifth" id="ignore">
          <b-field>
            <b-button
              id="ignore"
              type="is-primary"
              size="is-small"
              @click="$router.go(-1)"
              rounded
              outlined
              >Return to Assessment</b-button
            >
          </b-field>
        </div>
        <div class="column"></div>
        <div class="column is-one-fifth">
          <b-field grouped>
            <b-tooltip
              multilined
              label="By clicking the “Print” button, you will be directed to your default system printer where you can export to PDF or print a hard copy on your printer."
              type=" is-light"
              size="is-large"
              position="is-left"
            >
              <b-button
                id="ignore"
                type="is-primary"
                size="is-small"
                @click="generateReport()"
                rounded
                outlined
                >Print</b-button
              >
            </b-tooltip>
            <b-button
              style="margin-left: 10px"
              id="ignore"
              type="is-primary"
              size="is-small"
              @click="exit()"
              rounded
              outlined
              >Exit</b-button
            >
          </b-field>
        </div>
      </div>

      <div>
        <div class="has-text-left container is-fluid" :id="style">
          <div id="pdf-content">
            <div class="columns">
              <div class="column">
                <p class="title is-5" style="padding-top: 30px">
                  Health Risk Summary
                </p>
              </div>
              <div class="column is-one-fifth">
                <img
                  class=""
                  alt="careworks logo"
                  src="../../assets/bonitas.svg"
                />
              </div>
            </div>

            <b-message
              class="no-print"
              type="is-primary"
              aria-close-label="Close message"
              v-if="detailsNull"
            >
              Please complete the assessment to view your summary report.
            </b-message>

            <!-- <b-message
              class="no-print"
              id="ignore"
              type="is-secondary"
              aria-close-label="Close message"
              title="Info"
              v-if="!finished && !detailsNull"
            >
              You're assessment is incomplete. Finish the assessment before
              generating your report.
            </b-message> -->

            <b-button
              class="no-print"
              type="is-primary"
              v-if="detailsNull"
              @click="$router.go(-1)"
              outlined
              >back</b-button
            >
            <div v-if="!detailsNull">
              <b-field grouped class="userInfo">
                <b-field label="Name" expanded
                  ><p>{{ userDetails.fullName }}</p></b-field
                >
                <b-field label="Age" expanded
                  ><p>{{ userDetails.age }}</p></b-field
                >
                <b-field label="Sex" expanded
                  ><p>{{ capitalise(userDetails.gender) }}</p></b-field
                >
                <b-field label="Campaign" expanded
                  ><p>{{ userDetails.campaignCode }}</p></b-field
                >
                <b-field label="Date" expanded
                  ><p>
                    {{ dateString(userDetails.dateOfCompletion) }}
                  </p></b-field
                >
              </b-field>

              <div class="boxShadow">
                <base-section-summary
                  v-if="userDetails.cardioData != null && cardio"
                  title="Cardiovascular Assessment"
                  :riskCat="userDetails.cardioData.riskCat"
                  :message="
                    userDetails.cardioData.message != ''
                      ? userDetails.cardioData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.hypertensionData != null && hypertension"
                  title="Hypertension Assessment"
                  :riskCat="userDetails.hypertensionData.riskCat"
                  :message="
                    userDetails.hypertensionData.message != ''
                      ? userDetails.hypertensionData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.diabetesData != null && diabetes"
                  title="Diabetes Assessment"
                  :riskCat="userDetails.diabetesData.riskCat"
                  :message="
                    userDetails.diabetesData.message != ''
                      ? userDetails.diabetesData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="
                    userDetails.cervicalCancerData != null &&
                    cancer &&
                    userDetails.gender == 'female'
                  "
                  title="Cervical Cancer Assessment"
                  :riskCat="userDetails.cervicalCancerData.riskCat"
                  :message="
                    userDetails.cervicalCancerData.message != ''
                      ? userDetails.cervicalCancerData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="
                    userDetails.breastCancerData != null &&
                    cancer &&
                    userDetails.gender == 'female'
                  "
                  title="Breast Cancer Assessment"
                  :riskCat="userDetails.breastCancerData.riskCat"
                  :message="
                    userDetails.breastCancerData.message != ''
                      ? userDetails.breastCancerData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="
                    userDetails.colorectalCancerData != null &&
                    cancer &&
                    (userDetails.gender == 'female' ||
                      userDetails.gender == 'male')
                  "
                  title="Colorectal Cancer Assessment"
                  :riskCat="userDetails.colorectalCancerData.riskCat"
                  :message="
                    userDetails.colorectalCancerData.message != ''
                      ? userDetails.colorectalCancerData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="
                    userDetails.prostateCancerData != null &&
                    cancer &&
                    userDetails.gender == 'male'
                  "
                  title="Prostate Cancer Assessment"
                  :riskCat="userDetails.prostateCancerData.riskCat"
                  :message="
                    userDetails.prostateCancerData.message != ''
                      ? userDetails.prostateCancerData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.hivData != null && hiv"
                  title="HIV Assessment"
                  :riskCat="userDetails.hivData.riskCat"
                  :message="
                    userDetails.hivData.message != ''
                      ? userDetails.hivData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.tbData != null && hiv"
                  title="TB Assessment"
                  :riskCat="userDetails.tbData.riskCat"
                  :message="
                    userDetails.tbData.message != ''
                      ? userDetails.tbData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.stiData != null && hiv"
                  title="STI Assessment"
                  :riskCat="userDetails.stiData.riskCat"
                  :message="
                    userDetails.stiData.message != ''
                      ? userDetails.stiData.message
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.numbers != null && numbers"
                  :title="bmiTitle"
                  :riskCat="
                    isNaN(userDetails.numbers.bmi)
                      ? 'Not captured'
                      : userDetails.numbers.bmiRiskcat
                  "
                  :message="
                    userDetails.numbers.bmiMessage != ''
                      ? userDetails.numbers.bmiMessage
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.numbers != null && numbers"
                  :title="bpTitle"
                  :riskCat="
                    userDetails.numbers.SBP == null &&
                    userDetails.numbers.DBP == null
                      ? 'Not captured'
                      : userDetails.numbers.bpRiskcat
                  "
                  :message="
                    userDetails.numbers.bpMessage != ''
                      ? userDetails.numbers.bpMessage
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.numbers != null && numbers"
                  :title="bgTitle"
                  :riskCat="
                    userDetails.numbers.bgFasting == null &&
                    userDetails.numbers.bgRandom == null
                      ? 'Not captured'
                      : userDetails.numbers.glucoseRiskcat
                  "
                  :message="
                    userDetails.numbers.glucoseMessage != ''
                      ? userDetails.numbers.glucoseMessage
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.numbers != null && numbers"
                  :title="hb1acTitle"
                  :riskCat="
                    userDetails.numbers.bgHbaA1c == 0 ||
                    isNaN(userDetails.numbers.bgHbaA1c)
                      ? 'Not captured'
                      : userDetails.numbers.hba1cRiskcat
                  "
                  :message="
                    userDetails.numbers.hba1cMessage != ''
                      ? userDetails.numbers.hba1cMessage
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.numbers != null && numbers"
                  :title="cholTitle"
                  :riskCat="
                    userDetails.numbers.cholesterol == null
                      ? 'Not captured'
                      : userDetails.numbers.cholesterolRiskcat
                  "
                  :message="
                    userDetails.numbers.cholMessage != ''
                      ? userDetails.numbers.cholMessage
                      : 'None.'
                  "
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.mentalHealthData != null && mentalHealth"
                  title="Mental Health"
                  :riskCat="userDetails.mentalHealthData.risk_cat"
                  :message="userDetails.mentalHealthData.message"
                ></base-section-summary>
                <base-section-summary
                  v-if="userDetails.femaleHealthData != null && femaleHealth"
                  title="Female Health"
                  :riskCat="femaleHealthRiskCat"
                  :message="userDetails.femaleHealthData.message"
                ></base-section-summary>
              </div>
              <b-field style="padding-top: 20px; font-size: 13px">
                <p style="text-align: center">
                  You may contact CareWorks on 0800243671 if you would like to
                  chat to a CareWorks nurse about this assessment. You can now
                  also reach CareWorks on WhatsApp 0827354472.
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { jsPDF } from "jspdf";
// import $ from "jquery";
import BaseSectionSummary from "../ui/BaseSectionSummary.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseSectionSummary,
  },
  data() {
    return {
      style: "screenView",
      name: "",
      finished: false,
      detailsNull: false,
      userDetails: null,
      cardio: false,
      hypertension: false,
      diabetes: false,
      cancer: false,
      adhoc: false,
      numbers: false,
      hiv: false,
      femaleHealth: false,
      mentalHealth: false,
    };
  },
  methods: {
    ...mapGetters([
      "getUserObject",
      "getCardioData",
      "getCancerData",
      "getDiabetesData",
      "getHypertensionData",
      "getHIVData",
      "getSTIData",
      "getTBData",
      "getNumbersData",
      "getMentalHealthData",
      "getFemaleHealthData",
    ]),
    generateReport() {
      print();
      // const doc = new jsPDF("p", "pt", "a4");
      // $("#ignore").hide(); //to remove unwanted elements in pdf
      // var elementHTML = $("#pdf-content").html();

      // $("#ignore").show(); //show them in browser again
      // elementHTML = "<div style='width:600px; padding: 0px 0px 0px 0px;' >" + elementHTML + "</div>";

      // var filename = String(this.name + "-report.pdf");
      // doc.html(elementHTML, {
      //   callback: function(doc) {
      //     doc.save(filename);
      //   },
      //   margin: [72, 40, 72, 40],
      //   autoPaging: "text",
      //   html2canvas: {
      //     allowTaint: true,
      //     dpi: 300,
      //     letterRendering: true,
      //     logging: false,
      //     scale: 0.85,
      //   },
      // });
    },
    exit() {
      this.$buefy.dialog.confirm({
        title: "Exiting",
        message:
          "Are you sure you wish to finish? You cannot return to your assessment.",
        confirmText: "Exit",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$router.push("/");
        },
      });
    },
    capitalise(str) {
      return str[0].toUpperCase() + str.substring(1);
    },
    dateString(dateObj) {
      if (dateObj instanceof Date) {
        var dateArray = this.convertDateObject(dateObj);
        return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
      } else {
        return "";
      }
    },
    convertDateObject(date) {
      const dateStrings = [];
      if (date != null) {
        var dd = String(date.getDate()).padStart(2, "0");
        var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = date.getFullYear();

        dateStrings.push(dd);
        dateStrings.push(mm);
        dateStrings.push(yyyy);
      }
      return dateStrings;
    },

    assessmentFinished() {
      var fin = true;
      this.cardio = this.$store.getters.cardioFinished;
      this.hypertension = this.$store.getters.hypertensionFinished;
      this.numbers = this.$store.getters.numbersFinished;
      this.diabetes = this.$store.getters.diabetesFinished;
      this.hiv = this.$store.getters.hivFinished;
      this.adhoc = this.$store.getters.adhocFinished;
      this.cancer = this.$store.getters.cancerFinished;
      this.mentalHealth = this.$store.getters.mentalHealthFinished;
      this.femaleHealth = this.$store.getters.femaleHealthFinished;

      var sections = this.$store.getters.getCampaignSections;
      if (sections != null) {
        for (var i = 0; i < sections.length; i++) {
          if (sections[i] == "Cardio") {
            if (!this.cardio) {
              fin = false;
            }
          } else if (sections[i] == "Hypertension") {
            if (!this.hypertension) {
              fin = false;
            }
          } else if (sections[i] == "Health Check") {
            if (!this.numbers) {
              fin = false;
            }
          } else if (sections[i] == "Diabetes") {
            if (!this.diabetes) {
              fin = false;
            }
          } else if (sections[i] == "HIV/TB/STI") {
            if (!this.hiv) {
              fin = false;
            }
          } else if (sections[i] == "Campaign Specific") {
            if (!this.adhoc) {
              fin = false;
            }
          } else if (sections[i] == "Cancer") {
            if (!this.cancer) {
              fin = false;
            }
          } else if (sections[i] == "Mental Health") {
            if (!this.mentalHealth) {
              fin = false;
            }
          } else if (sections[i] == "Female Health") {
            if (!this.femaleHealth) {
              fin = false;
            }
          }
        }
      } else {
        fin = false;
      }
      console.log("Finished:", fin);
      return fin;
    },
  },
  computed: {
    femaleHealthRiskCat() {
      console.log(this.userDetails.femaleHealthData.outcome);
      if (
        ["first pregnancy", "subsequent pregnancy"].includes(
          this.userDetails.femaleHealthData.outcome
        )
      ) {
        if (this.userDetails.femaleHealthData.weeks_into_pregnancy != null) {
          return (
            this.userDetails.femaleHealthData.outcome +
            `\n Gestational age (calculated): ` +
            this.userDetails.femaleHealthData.gestational_age +
            " weeks" +
            `\n Gestational age (ultrasound): ` +
            this.userDetails.femaleHealthData.weeks_into_pregnancy +
            " weeks"
          );
        }

        return (
          this.userDetails.femaleHealthData.outcome +
          `\n Gestational age (calculated): ` +
          this.userDetails.femaleHealthData.gestational_age +
          " weeks"
        );
      }
      return this.userDetails.femaleHealthData.outcome;
    },
    bpTitle() {
      if (
        this.userDetails.numbers.SBP == null ||
        this.userDetails.numbers.DBP == null
      ) {
        return "Blood Pressure";
      }
      return String(
        "Blood Pressure  " +
          this.userDetails.numbers.SBP +
          "/" +
          this.userDetails.numbers.DBP +
          " mmHg"
      );
    },
    bgTitle() {
      if (
        this.userDetails.numbers.bgRandom == null &&
        this.userDetails.numbers.bgFasting == null
      ) {
        return "Blood Glucose";
      } else if (
        this.userDetails.numbers.bgRandom == null &&
        this.userDetails.numbers.bgFasting != null
      ) {
        return (
          "Blood Glucose  " + this.userDetails.numbers.bgFasting + " mmol/L"
        );
      } else if (
        this.userDetails.numbers.bgRandom != null &&
        this.userDetails.numbers.bgFasting == null
      ) {
        return (
          "Blood Glucose  " + this.userDetails.numbers.bgRandom + " mmol/L"
        );
      }
      return String(
        "Blood Glucose  " + this.userDetails.numbers.bgRandom + " mmol/L"
      );
    },
    cholTitle() {
      if (this.userDetails.numbers.cholesterol == null) {
        return "Cholesterol";
      }
      return String(
        "Cholesterol  " + this.userDetails.numbers.cholesterol + " mmol/L"
      );
    },
    bmiTitle() {
      if (
        this.userDetails.numbers.bmi == null ||
        isNaN(this.userDetails.numbers.bmi)
      ) {
        return "BMI";
      }
      return String("BMI  " + this.userDetails.numbers.bmi);
    },
    hb1acTitle() {
      if (
        this.userDetails.numbers.bgHbaA1c == null ||
        this.userDetails.numbers.bgHbaA1c == 0
      ) {
        return "HbA1c";
      }
      return String("HbA1c  " + this.userDetails.numbers.bgHbaA1c + " %");
    },
  },
  created() {
    this.finished = this.assessmentFinished(); //used for checking if assessment is complete and showing the sections that are complete only
    
    this.$buefy.dialog.confirm({
      title: "Assessment Complete",
      message:
        "Thank you for successfully completing the CareWorks Self Health Evaluation.\n By clicking the 'Print' button on your Health Risk Summary Screen, you will be directed to your default system printer where you can export to PDF or print a hard copy on your printer",
      confirmText: "Continue",
      type: "is-primary",
    });

    const userObj = this.getUserObject();
    console.log(userObj);
    if (userObj != null && userObj.name != "") {
      this.name = userObj.name;
      this.detailsNull = false;
      this.userDetails = userObj;
      this.userDetails.cardioData = this.getCardioData();
      this.userDetails.hypertensionData = this.getHypertensionData();
      this.userDetails.diabetesData = this.getDiabetesData();
      var allCancerData = this.getCancerData();
      if (allCancerData) {
        this.userDetails.cancerData = allCancerData.legacy;
        this.userDetails.cervicalCancerData = allCancerData.cervical;
        this.userDetails.prostateCancerData = allCancerData.prostate;
        this.userDetails.colorectalCancerData = allCancerData.colorectal;
        this.userDetails.breastCancerData = allCancerData.breast;
      }
      this.userDetails.hivData = this.getHIVData();
      this.userDetails.tbData = this.getTBData();
      this.userDetails.stiData = this.getSTIData();
      this.userDetails.numbers = this.getNumbersData();
      this.userDetails.mentalHealthData = this.getMentalHealthData();
      this.userDetails.femaleHealthData = this.getFemaleHealthData();
      console.log("Mental", this.userDetails.mentalHealthData)
      console.log(this.userDetails);
    } else {
      this.detailsNull = true;
    }
  },
};
</script>

<style scoped>
@media only screen {
  .boxShadow {
    box-shadow: 2px 2px 2px 2px #002037;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
  }
  .userInfo {
    padding: 15px 0px 35px 0px;
  }

  .logo {
    width: 20%;
    /* height: 65%; */
  }
}

@media only print {
  @page {
    size: auto; /* auto is the initial value */
    margin-top: 10%;
    margin-bottom: 10%;
    margin-right: 4%;
    margin-left: 4%;
  }
  .no-print {
    display: none !important;
  }

  .boxShadow {
    box-shadow: none;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
  }
}
</style>
