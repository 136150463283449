<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
@import "./custom-styles.scss";
#app {
  font-family: "Facit", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333436;
}

/* #nav {
  padding: 60px 0px 30px 200px;
} */

#nav .navbar-brand {
  text-align: center;
  display: block;
  /* width: 50%;  */
}

.navbar .navbar-brand > .navbar-item,
.navbar .navbar-brand .navbar-link {
  display: inline-block;
}

#nav a {
  font-weight: bold;
  color: $secondary;
}
#nav a.router-link-exact-active {
  color: $primary;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  padding: 12px 12px 12px 12px;
  font-size: 14px;
}

#info {
  color: $secondary;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  /* font-size:12px; */
}

.submitButton {
  margin-top: 15px;
}
div.radioField div.field.has-addons.has-addons-centered {
  justify-content: space-evenly;
}

.navbar-burger {
  margin: auto !important;
}

.message.is-primary .message-body {
  color: $secondary !important;
}
</style>
