<template>
  <div>
    <div
      class="columns is-centered is-3-desktop is-0-mobile"
      style="padding-top: 20px"
      is-flex-wrap-wrap
    >
      <div class="column is-one-fifth"><the-logo></the-logo></div>
      <div class="column" style="padding-top: 20px;">
        <div>
          <b-navbar id="nav" :centered="true">
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Personal' }">
                Personal
              </b-navbar-item>
            </template>

            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Cardio' }">
                <b-tag
                  v-if="cardio"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Cardio</b-tag
                >
                <p v-if="!cardio">Cardio</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Hypertension' }">
                <b-tag
                  v-if="hypertension"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Hypertension</b-tag
                >
                <p v-if="!hypertension">Hypertension</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Diabetes' }">
                <b-tag
                  v-if="diabetes"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Diabetes</b-tag
                >
                <p v-if="!diabetes">Diabetes</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Numbers' }">
                <b-tag
                  v-if="numbers"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Health Check</b-tag
                >
                <p v-if="!numbers">Health Check</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Cancer' }">
                <b-tag
                  v-if="cancer"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Cancer Prevention</b-tag
                >
                <p v-if="!cancer">Cancer Prevention</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'HIV' }">
                <b-tag v-if="hiv" type="is-success is-light" size="is-medium">
                  HIV</b-tag
                >
                <p v-if="!hiv">HIV</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'MentalHealth' }">
                <b-tag
                  v-if="mentalHealth"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Mental Health</b-tag
                >
                <p v-if="!mentalHealth">Mental Health</p>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'FemaleHealth' }">
                <b-tag
                  v-if="femaleHealth"
                  type="is-success is-light"
                  size="is-medium"
                >
                  Reproductive Health</b-tag
                >
                <p v-if="!femaleHealth">Reproductive Health</p>
              </b-navbar-item>
            </template>

            <!-- <template slot="start">
              <b-navbar-item tag="router-link" :to="{ name: 'Adhoc' }">
                <b-tag v-if="adhoc" type="is-success is-light" size="is-medium">
                  {{ label }}</b-tag
                >
                <p v-if="!adhoc">{{ label }}</p>
              </b-navbar-item>
            </template> -->
          </b-navbar>
        </div>
      </div>
      <div class="column is-one-fifth" style="padding-top: 20px">
        <b-field grouped position="is-centered">
          <b-field>
            <b-button
              class=""
              @click="goToReport"
              outlined
              type="is-secondary"
              size="is-default"
              rounded
              >Report</b-button
            >
          </b-field>
          <b-field>
            <b-button
              class=""
              @click="exitAssessment"
              outlined
              type="is-primary"
              size="is-default"
              rounded
              >Exit</b-button
            >
          </b-field>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { LocalStorage } from "../../services/storage.service";
import TheLogo from "./TheLogo.vue";
export default {
  components: {
    TheLogo,
  },
  data() {
    return {
      loggedIn: true,
      label: "Other",
      cardio: false,
      hypertension: false,
      numbers: false,
      diabetes: false,
      hiv: false,
      cancer: false,
      adhoc: false,
      mentalHealth: false,
      femaleHealth: false,
    };
  },
  methods: {
    exitAssessment() {
      if (this.$route.name != "Personal") {
        this.$buefy.dialog.confirm({
          title: "Exiting",
          message:
            "Are you sure you want to exit the  Self Health Evaluation? All of your progress will be lost.",
          confirmText: "Exit",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            LocalStorage.remove("userDetails");
            this.$router.push("/");
          },
        });
      } else {
        this.$router.push("/");
      }
    },
    goToReport() {
      this.$router.push({ name: "SummaryReport" });
    },
    assessmentSectionStatus() {
      this.cardio = this.$store.getters.cardioFinished;
      this.hypertension = this.$store.getters.hypertensionFinished;
      this.numbers = this.$store.getters.numbersFinished;
      this.diabetes = this.$store.getters.diabetesFinished;
      this.hiv = this.$store.getters.hivFinished;
      this.adhoc = this.$store.getters.adhocFinished;
      this.cancer = this.$store.getters.cancerFinished;
      this.mentalHealth = this.$store.getters.mentalHealthFinished;
      this.femaleHealth = this.$store.getters.femaleHealthFinished;
    },
  },
  created() {
    this.assessmentSectionStatus();
  },
};
</script>

<style scoped>
.exit {
  position: relative;
  top: 60px;
  padding: 10px 10px 10px 10px;
  margin-right: 20px;
}
.report {
  position: relative;
  top: 60px;
  padding: 10px 10px 10px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.centered {
  text-align: center;
  /* margin: auto;
   width: 120%; */
  /* padding: 50px 10px 10px 10px;  */
}
</style>
