<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 150px;
        padding-left: 20px;
      "
    >
      <b-field>
        <p class="title is-5">Reproductive Health</p>
      </b-field>
      <b-field
        ><p id="info" @click="showFemaleHealthInfo()">
          {{ femaleHealthInfo }}
        </p></b-field
      >
      <b-field
        style="color: $primary; width: device-width"
        v-if="femaleHealthInfoVisible"
        ><p style="width: device-width">{{ femaleHealthInfoText }}</p></b-field
      >
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="FemaleHealth"
        @clearFields="clear()"
      ></the-nav-buttons>

      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="gender != 'female'"
      >
        This section is only applicable to female participants, click finish to continue.
      </b-message>

      <form
        @submit.prevent="submitFemaleHealthData()"
        style="padding-top: 15px"
        v-if="gender == 'female'"
      >
        <question
          :index="1"
          question="Are you pregnant now?"
          type="yes/no"
          :value="femaleHealthData.currently_pregnant"
          @answered="updateObject"
          dataProperty="currently_pregnant"
        ></question>
        <question
          v-if="femaleHealthData.currently_pregnant == 'yes'"
          :index="2"
          question="What was the date on the 1st day of your last menstrual period?"
          type="date"
          :value="femaleHealthData.start_date_of_last_period"
          @answered="updateObject"
          dataProperty="start_date_of_last_period"
        ></question>

        <p
          class="title is-6"
          v-if="femaleHealthData.start_date_of_last_period != null"
        >
          Estimated Date of Delivery: {{ estimatedDueDate }}
        </p>
        <p
          class="title is-6"
          v-if="femaleHealthData.start_date_of_last_period != null"
        >
          Gestational Age: {{ gestationalAge }} weeks
        </p>

        <question
          v-if="femaleHealthData.currently_pregnant == 'yes'"
          :index="3"
          question="Have you had a pregnancy ultrasound scan?"
          type="yes/no"
          :value="femaleHealthData.had_ultrasound"
          @answered="updateObject"
          dataProperty="had_ultrasound"
        ></question>
        <b-field
          v-if="femaleHealthData.had_ultrasound == 'yes'"
          label="3a. How many weeks pregnant are you?"
        >
          <b-tooltip
            label="Enter a number from 8-42"
            type=" is-light"
            position="is-bottom"
          >
            <b-numberinput
              v-model="femaleHealthData.weeks_into_pregnancy"
              controls-alignment="right"
              controls-position="compact"
              size="is-small"
              :min="8"
              :max="42"
            ></b-numberinput>
          </b-tooltip>
        </b-field>
        <question
          v-if="femaleHealthData.currently_pregnant == 'no'"
          :index="2"
          question="Are you trying to fall pregnant now?"
          type="yes/no"
          :value="femaleHealthData.trying_to_get_pregnant"
          @answered="updateObject"
          dataProperty="trying_to_get_pregnant"
        ></question>
        <question
          v-if="
            (femaleHealthData.trying_to_get_pregnant == 'yes') |
              (femaleHealthData.had_ultrasound != null)
          "
          :index="femaleHealthData.currently_pregnant == 'no' ? 3 : 4"
          question="How many times have you been pregnant for longer than 20 weeks?"
          type="dropdown"
          :value="femaleHealthData.pregnant_count"
          @answered="updateObject"
          dataProperty="pregnant_count"
          :dropdownOptions="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        ></question>
        <question
          v-if="
            (femaleHealthData.pregnant_count != null) &
            (femaleHealthData.pregnant_count > 0)
          "
          :index="femaleHealthData.currently_pregnant == 'no' ? 4 : 5"
          question="How many children have you given birth to?"
          type="dropdown"
          :value="femaleHealthData.children_count"
          @answered="updateObject"
          dataProperty="children_count"
          :dropdownOptions="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        ></question>
        <question
          v-if="hasHadChildrenOrIscurrently_pregnant"
          :index="femaleHealthData.currently_pregnant == 'no' ? 5 : 6"
          question="Were you diagnosed with high blood pressure (hypertension) during any previous pregnancy?"
          type="yes/no"
          :value="femaleHealthData.hypertensive_while_pregnant"
          @answered="updateObject"
          dataProperty="hypertensive_while_pregnant"
        ></question>
        <question
          v-if="hasHadChildrenOrIscurrently_pregnant"
          :index="femaleHealthData.currently_pregnant == 'no' ? 6 : 7"
          question="Were you diagnosed with high blood sugar (diabetes) during any previous pregnancy?"
          type="yes/no"
          :value="femaleHealthData.diabetic_while_pregnant"
          @answered="updateObject"
          dataProperty="diabetic_while_pregnant"
        ></question>
        <question
          v-if="hasHadChildrenOrIscurrently_pregnant"
          :index="femaleHealthData.currently_pregnant == 'no' ? 7 : 8"
          question="In your last pregnancy, did you carry to term (38 weeks)??"
          type="yes/no"
          :value="femaleHealthData.carry_to_term"
          @answered="updateObject"
          dataProperty="carry_to_term"
        ></question>
        <question
          v-if="
            (femaleHealthData.children_count > 0) &
            (this.femaleHealthData.children_count != null)
          "
          :index="femaleHealthData.currently_pregnant == 'no' ? 8 : 9"
          question="In your last pregnancy, did you have a Caesarean section delivery?"
          type="yes/no"
          :value="femaleHealthData.caesarean"
          @answered="updateObject"
          dataProperty="caesarean"
        ></question>
        <b-field
          label="What is your height in metres?"
          v-if="femaleHealthData.pregnant_count == '0'"
        >
          <b-tooltip
            label="Height is measured in metres (m), for example 1,7"
            type=" is-light"
            position="is-bottom"
          >
            <b-numberinput
              v-model="femaleHealthData.height_in_m"
              controls-alignment="right"
              controls-position="compact"
              size="is-small"
              step="0.01"
              :min="0"
              :max="2.4"
            ></b-numberinput>
          </b-tooltip>
        </b-field>
        <question
          v-if="hasBeenPregnantButNoChildren"
          :index="
            hasBeenPregnantButNoChildren &
            (this.femaleHealthData.currently_pregnant == 'no')
              ? 5
              : 9
          "
          question="Have you ever had a miscarriage?"
          type="yes/no"
          :value="femaleHealthData.miscarriage"
          @answered="updateObject"
          dataProperty="miscarriage"
        ></question>
        <question
          v-if="hasBeenPregnantButNoChildren"
          :index="
            hasBeenPregnantButNoChildren &
            (this.femaleHealthData.currently_pregnant == 'no')
              ? 6
              : 10
          "
          question="Did you ever have to have a termination of pregnancy?"
          type="yes/no"
          :value="femaleHealthData.abortion"
          @answered="updateObject"
          dataProperty="abortion"
        ></question>
        <question
          v-if="
            (femaleHealthData.children_count > 0) &
            (this.femaleHealthData.children_count != null)
          "
          :index="femaleHealthData.currently_pregnant == 'no' ? 9 : 10"
          question="Have you ever struggled with depression after any previous deliveries?"
          type="yes/no"
          :value="femaleHealthData.post_natal_depression"
          @answered="updateObject"
          dataProperty="post_natal_depression"
        ></question>
        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit and finish</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
      <b-button
        v-if="gender != 'female'"
        type="is-primary"
        outlined
        native-type="submit"
        class="submitButton"
        @click="goToReport()"
        >finish</b-button
      >
    </div>
  </div>
</template>

<script>
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
import question from "../ui/Question.vue";

export default {
  components: {
    NavBar,
    question,
    TheNavButtons,
  },
  data() {
    return {
      name: "FemaleHealth",
      femaleHealthInfoText:
        "These are questions are mostly about whether you are trying to fall pregnant, or are already pregnant. Once you are pregnanct it is really important engage with your caregiver as early as possible in order to establish an accurate gestational age (GA) and if there are any high risk indicators that might affect the health of you, your baby or both of you. The earlier you and your doctor know the state of your pregnancy, the better you and your baby can be taken care of. You can also learn more about your health by completing the other sections of this Self Health Evaluation.",
      femaleHealthInfoVisible: false,
      femaleHealthInfo: "more information",
      visible: true,
      isLoading: false,
      continue: false,
      gender: null,
      femaleHealthData: {
        reference_num: null,
        currently_pregnant: null,
        start_date_of_last_period: null,
        had_ultrasound: null,
        weeks_into_pregnancy: null,
        trying_to_get_pregnant: null,
        pregnant_count: null,
        children_count: null,
        hypertensive_while_pregnant: null,
        diabetic_while_pregnant: null,
        carry_to_term: null,
        caesarean: null,
        height_in_m: null,
        miscarriage: null,
        abortion: null,
        post_natal_depression: null,
        estimated_due_date: null,
        gestational_age: null,
        outcome: null,
        message: null,
      },
    };
  },
  methods: {
    goToReport(){
      this.$router.push({ name: "SummaryReport" });
    },
    showFemaleHealthInfo() {
      this.femaleHealthInfoVisible = !this.femaleHealthInfoVisible;
      if (this.femaleHealthInfoVisible) {
        this.femaleHealthInfo = "hide";
      } else {
        this.femaleHealthInfo = "more information";
      }
    },
    updateObject(answer, dataProperty) {
      this.femaleHealthData[dataProperty] = answer;
    },
    determineOutcome() {
      if (this.femaleHealthData.currently_pregnant == "no") {
        if (this.femaleHealthData.trying_to_get_pregnant == "no") {
          return "not pregnant";
        } else {
          if (this.femaleHealthData.pregnant_count == "0") {
            return "trying for first pregnancy";
          } else {
            return "trying to fall pregnant again";
          }
        }
      }
      if (this.femaleHealthData.currently_pregnant == "yes") {
        if (this.femaleHealthData.pregnant_count == "0") {
          return "first pregnancy";
        } else {
          return "subsequent pregnancy";
        }
      }
      return "undetermined";
    },
    async getActionMessage(riskCat, section) {
      let resp = QueryService.get_ActionMessage(section, null, riskCat)
        .then((res) => {
          console.log(res);
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            return res.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
      return resp;
    },

    async submitFemaleHealthData() {
      this.isLoading = true;
      this.femaleHealthData.outcome = this.determineOutcome();
      var resp = await this.getActionMessage(
        this.femaleHealthData.outcome,
        "female health"
      );
      if (resp) {
        this.femaleHealthData.message = resp.message_female;
      }
      this.femaleHealthData.reference_num = this.$store.getters.getRefNum;
      this.femaleHealthData.gestational_age = this.gestationalAge;
      this.femaleHealthData.estimated_due_date = this.addDays(
        this.femaleHealthData.start_date_of_last_period,
        280
      );
      this.$store.dispatch("saveFemaleHealthData", this.femaleHealthData);
      this.saveMentalHealthToDB(this.femaleHealthData);
      console.log(this.femaleHealthData);
    },
    saveMentalHealthToDB(obj) {
      this.isLoading = true;
      QueryService.update_withFemaleHealth(obj)
        .then((response) => {
          console.log(response);
          this.successToast("saved female health data", 2000);
          this.isLoading = false;
          this.continue = true;
          this.$store.dispatch("setFemaleHealthFinished", true);
        })
        .catch((error) => {
          console.log(error);
          this.errorToast("error saving mental health data", 4000);
          this.isLoading = false;
        });
    },
    clear() {
      this.femaleHealthData = {
        currently_pregnant: null,
        start_date_of_last_period: null,
        had_ultrasound: null,
        weeks_into_pregnancy: null,
        trying_to_get_pregnant: null,
        pregnant_count: null,
        children_count: null,
        hypertensive_while_pregnant: null,
        diabetic_while_pregnant: null,
        carry_to_term: null,
        caesarean: null,
        height_in_m: null,
        miscarriage: null,
        abortion: null,
        post_natal_depression: null,
        estimated_due_date: null,
        gestational_age: null,
        outcome: null,
        message: null,
      };
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
  computed: {
    hasHadChildrenOrIscurrently_pregnant() {
      return (
        ((this.femaleHealthData.children_count != "0") &
          (this.femaleHealthData.children_count != null)) |
        ((this.femaleHealthData.children_count == "0") &
          (this.femaleHealthData.currently_pregnant == "yes"))
      );
    },
    hasBeenPregnantButNoChildren() {
      return (
        (this.femaleHealthData.children_count == "0") &
        (this.femaleHealthData.pregnant_count != "0") &
        (this.femaleHealthData.pregnant_count != null)
      );
    },
    estimatedDueDate() {
      if (this.femaleHealthData.start_date_of_last_period == null) {
        return null;
      }
      //add 280 days to the start date of the last period
      return this.convertDate(
        this.addDays(this.femaleHealthData.start_date_of_last_period, 280),
        "english"
      );
    },
    gestationalAge() {
      if (this.femaleHealthData.start_date_of_last_period == null) {
        return null;
      }
      var today = new Date();
      var diff = today - this.femaleHealthData.start_date_of_last_period;
      var days = diff / (1000 * 60 * 60 * 24);
      var weeks = Math.floor(days / 7);
      //add 280 days to the start date of the last period
      return weeks;
    },
  },
  watch: {
    continue() {
      if (this.continue == true) {
        this.continue = false;
        this.$router.push({ name: "SummaryReport" });
      }
    },
    "femaleHealthData.children_count": function () {
      if (
        this.femaleHealthData.children_count >
        this.femaleHealthData.pregnant_count
      ) {
        this.warningToast(
          "Be aware you are recording more children than pregnancies",
          4000
        );
      }
    },
  },
  created() {
    this.gender = this.$store.getters.getGender;

    const femaleHealthObject = this.$store.getters.getFemaleHealthData;
    if (femaleHealthObject) {
      //if not null
      this.femaleHealthData = femaleHealthObject;
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.dispatch("saveFemaleHealthData", this.femaleHealthData); //save details upon leaving page
    next();
  },
};
</script>

<style scoped>
.customQuestion {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>