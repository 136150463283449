<template>
  <div>
    <div class="container" style="padding: 50px 30px 0px 30px">
      <b-field>
        <b-button
          class="exit"
          v-if="!takeAssessment"
          size="is-small"
          icon-left="cog"
          rounded
          @click="toggleAssessment('admin')"
          >admin portal</b-button
        >
        <b-button
          class="exit"
          v-if="takeAssessment"
          @click="toggleAssessment('exit')"
          >Exit</b-button
        >
      </b-field>
      <!--top, right, bottom, lefts-->
      <img
        class=""
        alt="careworks logo"
        style="width: 50%; height: 50%;"
        src="../assets/bonitas.svg"
      />
     

      <div style="padding-top:30px;">

         <p class="title is-5" style="font-weight:normal;">
        Self Health Evaluation
      </p>

        <b-field
          ><p id="consent" v-if="!takeAssessment" @click="showConsentMessage()">
            {{ consentText }}
          </p></b-field
        >

        <b-field v-if="consentVisible && !takeAssessment" style="color:$primary"
          ><p>
            {{ consentMessage1 }}<br /><br />{{ consentMessage2 }}<br /><br />{{
              consentMessage3
            }}<br /><br />{{ consentMessage4 }}
          </p></b-field
        >
        <!-- text-decoration: underline; -->
        <b-button
          @click="toggleAssessment('next')"
          v-if="!takeAssessment"
          outlined
          type="is-primary"
        >
          Take Assessment
        </b-button>
      </div>
    </div>
    <div class="container">
      <div>
        <form v-if="admin" @submit.prevent="handleLogin()">
          <p class="title is-5">Admin Portal</p>
          <div class="columns" style="text-align:left">
            <div class="column is-three-fifths is-offset-one-fifth ">
            <b-field label="Email" :type="emailFieldType">
              <b-input v-model.trim="email" />
            </b-field>
            <b-field label="Password" :type="passwordFieldType">
              <b-input
                type="password"
                v-model.trim="password"
                password-reveal
              />
            </b-field>
            <b-field>
              <b-button
                style="margin-top:20px; "
                @click="toggleAssessment('back')"
                type="is-primary"
                outlined
                >back</b-button
              >
              <b-button
                grouped
                style="margin-top:20px; margin-left:10px;"
                native-type="submit"
                type="is-primary"
                outlined
                >next</b-button
              >
            </b-field>
            </div>
          </div>
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </form>
      </div>
    </div>
    <footer id="footer">
      <p><a href="https://www.careworks.co.za/"> Powered by CareWorks</a></p>
    </footer>
  </div>
</template>

<script>
//import TheLogo from "./ui/TheLogo.vue";
import { UserService } from "../services/user.service";

export default {
  components: {
    // TheLogo,
  },
  data() {
    return {
      validEmail: true,
      validPassword: true,
      loggedIn: false,
      isLoading: false,
      email: "",
      password: "",
      takeAssessment: false,
      admin: false,
      consentVisible: false,
      consentText: "About this assessment",
      consentMessage1:
        "Knowing your health status is important. It empowers you to take control of your health. If you manage your health risks, you can live a healthier life!",
      consentMessage2:
        "This website is a tool designed to assess your health risks. It will help us refer you for the right tests if you need them. It is important to note that this tool is an assessment only; it does not replace professional medical opinion. It cannot be used for diagnostic purposes and should not stop you from getting medical care",
      consentMessage3:
        "The assessment takes about 5-10 minutes to complete. We will use the information you give us to decide if a CareWorks nurse should call you to discuss your results. We might recommend that you get your Blood Pressure, Cholesterol or Blood Sugar tested. If you are unsure about your risk, please see your healthcare provider",
      consentMessage4:
        "All your answers are private and confidential. They will not be published or given to your Employer in any form. You do not have to complete this assessment; it is 100% voluntary. Even if you do not complete the assessment, our services to you will not be affected in any way.",
    };
  },
  computed: {
    emailFieldType() {
      if (!this.validEmail) {
        return "is-danger";
      }
      return "is-primary";
    },
    passwordFieldType() {
      if (!this.validPassword) {
        return "is-danger";
      } else {
        return "is-primary";
      }
    },
  },
  methods: {
    async handleLogin() {
      console.log("Trying to log in");
      this.isLoading = true;
      let res = await UserService.login(this.email, this.password);
      this.isLoading = false;
      if (res.status == 200 && res.data.access_token != "") {
        this.validEmail = true;
        this.validPassword = true;
        this.$router.push({ name: "Admin" });
      } else {
        this.validPassword = true;
        this.validEmail = true;
        if (res.data.passwordErr) {
          this.validPassword = false;
        }
        if (res.data.emailErr) {
          this.validEmail = false;
        }
        this.errorToast("Invalid credentials", 3000);
      }
    },
    toggleAssessment(source) {
      if (source == "next") {
        this.takeAssessment = !this.takeAssessment;
        this.admin = false;
        //LocalStorage.remove("userDetails");
        this.$store.dispatch("clearUserDetails");
        this.$store.dispatch("clearCampaign");
        this.$store.dispatch("clearCancer");
        this.$store.dispatch("clearCardio");
        this.$store.dispatch("clearHypertension");
        this.$store.dispatch("clearNumbers");
        this.$store.dispatch("clearHIV");
        this.$store.dispatch("clearDiabetes");
        this.$store.dispatch("clearMentalHealth");  
        this.$store.dispatch("clearFemaleHealth");
        this.$store.dispatch("setFormValidity", false);
        this.$store.dispatch("setFinishedFalse");
        this.$router.push({ name: "Personal" });
      } else if (source == "exit" && this.takeAssessment) {
        this.takeAssessment = !this.takeAssessment;
        this.admin = false;
        this.consentVisible = false;
        this.consentText = "About this assessment";
      } else if (source == "admin") {
        this.admin = true;
        this.takeAssessment = !this.takeAssessment;
      } else if (source == "back") {
        this.takeAssessment = !this.takeAssessment;
        this.admin = false;
        this.consentVisible = false;
        this.consentText = "About this assessment";
      }
    },
    showConsentMessage() {
      var text = ["hide", "About this assessment"];
      if (this.consentText == text[0]) {
        this.consentText = text[1];
        this.consentVisible = false;
      } else {
        this.consentText = text[0];
        this.consentVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../custom-styles.scss";
.hra {
  position: absolute;
  top: 20px;
  left: 20px;
}

.exit {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 10px 10px 10px;
}
.admin {
  position: absolute;
  top: 20px;
  right: 100px;
  padding: 10px 10px 10px 10px;
}
#consent {
  color: $secondary;
  text-decoration: underline;
  border: none;
  cursor: pointer;
}
#footer {
  display: block;
  position: relative;
  padding-top: 200px;
  color: #002037;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  height: 2.5rem; /* Footer height */
}

/* .footer {
  footer-color: var(false);
  footer-padding: var(1rem 1rem 1rem);
} */
</style>
