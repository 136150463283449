import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Intro from "../components/Intro.vue";
import Cancer from "../components/pages/Cancer.vue";
import Cardio from "../components/pages/Cardio.vue";
import Diabetes from "../components/pages/Diabetes.vue";
import HIV from "../components/pages/HIV.vue";
import Hypertension from "../components/pages/Hypertension.vue";
import MentalHealth from "../components/pages/MentalHealth.vue";
import FemaleHealth from "../components/pages/FemaleHealth.vue";
import Personal from "../components/pages/Personal.vue";
import AdminPortal from "../components/pages/AdminPortal.vue";
import SummaryReport from "../components/pages/SummaryReport.vue";
import RiskEditor from "../components/admin/RiskEditor.vue";
import Numbers from "../components/pages/Numbers.vue";
import AdhocQuestions from "../components/pages/AdhocQuestions.vue";
import { TokenService } from "../services/storage.service";
import { ToastProgrammatic as toast } from "buefy";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/intro",
    name: "Intro",
    component: Intro,
  },
  {
    path: "/cardio",
    name: "Cardio",
    component: Cardio,
  },
  {
    path: "/diabetes",
    name: "Diabetes",
    component: Diabetes,
  },
  {
    path: "/hiv",
    name: "HIV",
    component: HIV,
  },
  {
    path: "/cancer",
    name: "Cancer",
    component: Cancer,
  },
  {
    path: "/hypertension",
    name: "Hypertension",
    component: Hypertension,
  },
  {
    path: "/personal",
    name: "Personal",
    component: Personal,
  },
  {
    path: "/mentalhealth",
    name: "MentalHealth",
    component: MentalHealth,
  },
  {
    path: "/femaleHealth",
    name: "FemaleHealth",
    component: FemaleHealth,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminPortal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report",
    name: "SummaryReport",
    component: SummaryReport,
  },
  {
    path: "/risk",
    name: "Risk",
    component: RiskEditor,
  },
  {
    path: "/numbers",
    name: "Numbers",
    component: Numbers,
  },
  {
    path: "/other",
    name: "Adhoc",
    component: AdhocQuestions,
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (TokenService.getToken("access_token") == null) {
      next({
        path: "/home",
        params: { nextUrl: to.fullPath },
      });
      toast.open({
        duration: 2000,
        message: "Authorization required",
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: false,
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ name: "userboard" });
    }
  } 
  // else if (from.name == "Personal") {
  //   var userDetailsCaptured = Store.getters.getFormValidity;
  //   if (userDetailsCaptured) {
  //     next();
  //   } else {
  //     next()
  //     console.log("hade");
  //   }
  // }
  else{
    next();
  }
});

export default router;
