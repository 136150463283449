<template>
  <div>
    <div v-if="loggedIn">
      <div class="exit">
        <b-field>
          <b-icon pack="fas" icon="user" type="is-secondary"> </b-icon>
          <p>{{ username }}</p>
        </b-field>
      </div>
      <div class="columns is-mobile" style="padding-top:60px;">
        <div class="column">
          <p class="title is-5">Admin Portal</p>
        </div>

        <div class="column">
          <template>
            <b-tabs type="is-primary" v-model="activeTab">
              <b-tab-item value="Query" label="Query"> </b-tab-item>
              <b-tab-item value="Risk" label="Edit Risk Categories">
              </b-tab-item>
              <b-tab-item value="Campaign" label="Campaign Editor">
              </b-tab-item>
              <b-tab-item value="AddUser" label="Admin"> </b-tab-item>
            </b-tabs>
          </template>
        </div>

        <div class="column">
          <b-button @click="exit()" rounded>log out</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TokenService } from "../../services/storage.service";
import UserService from "../../services/user.service";

export default {
  data() {
    return {
      activeTab: this.value,
      loggedIn: true,
      username: TokenService.getUser(),
    };
  },
  props: {
    value: String,
  },
  methods: {
    exitAssessment() {
      this.$buefy.dialog.confirm({
        title: "Exiting",
        message:
          "Are you sure you want to exit the  Self Health Evaluation? All of your progress will be lost.",
        confirmText: "Exit",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$router.push("/");
        },
      });
    },
    goToReport() {
      this.$router.push({ name: "SummaryReport" });
    },
    exit() {
      UserService.logout();
      this.successToast("logged out", 500);
      this.$router.push({ name: "Home" });
    },
  },
  watch: {
    activeTab: function() {
      this.$emit("tabChanged", this.activeTab);
    },
  },
};
</script>

<style scoped>
.exit {
  position:absolute;
  top:20px;
  right:20px;  
}
.report {
  position: relative;
  top: 60px;
  padding: 10px 10px 10px 10px;
  margin-right: 10px;
}

.centered {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 60px 10px 10px 10px;
}
</style>
