<template>
  <section>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field grouped>
        <p class="title is-5">HIV/TB/STI</p>
      </b-field>

      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="HIV"
        @clearFields="clear()"
      ></the-nav-buttons>
      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to complete this section of this assessment
      </b-message>
      <form
        @submit.prevent="submitHIVData"
        style="padding-top:15px"
        v-if="visible"
      >
        <b-field label="HIV Assessment"></b-field>

        <b-field
          ><p id="info" @click="showHivInfo()">
            {{ hivInfo }}
          </p></b-field
        >
        <b-field
          style="color:$primary; width: device-width"
          v-if="hivInfoVisible"
          ><p>{{ hivInfoMessage }}</p></b-field
        >
        <question
          :index="1"
          question="Have you ever had an HIV test?"
          radioName="hadTest"
          dataProperty="hivTest"
          @answered="updateObject"
          :value="hivData.hadTest"
          type="yes/no"
        ></question>

         <question
         v-if="hivData.hadTest=='yes'"
          :index="2"
          question="What was the result of your test?"
          dataProperty="hivTestResult"
          @answered="updateObject"
          :value="hivData.testResult"
          type="dropdown"
          :dropdownOptions="['positive', 'negative', 'rather not say']"
        >
        </question>


       

       
        <question
          v-if="lessThan6Months"
          :index="3"
          question="Was the test done less than 6 months ago?"
          radioName="when"
          dataProperty="recentTest"
          @answered="updateObject"
          :value="hivData.recentTest"
          type="yes/no"
        ></question>

        <question
          v-if="hivData.testResult == 'positive' && hivData.hadTest == 'yes'"
          :index="3"
          question="Are you taking ARVs?"
          radioName="arv"
          dataProperty="arv"
          @answered="updateObject"
          :value="hivData.onMedication"
          type="yes/no"
        >
        </question>

        <b-field label="TB Assessment" style="padding-top:15px;"></b-field>
        <b-field
          ><p id="info" @click="showTbInfo()">
            {{ tbInfo }}
          </p></b-field
        >
        <b-field
          style="color:$primary; width: device-width"
          v-if="tbInfoVisible"
          ><p>{{ tbInfoMessage }}</p></b-field
        >
        <question
          :index="1"
          question="Have you been coughing a lot for longer than 2 weeks (or, if you are HIV Positive, have you been coughing at all)?"
          radioName="cough"
          dataProperty="cough"
          @answered="updateObject"
          :value="tbData.persistentCough"
          type="yes/no"
        ></question>

        <question
          :index="2"
          question="Have you had a fever or high temperature for longer than 2 weeks?"
          radioName="fever"
          dataProperty="fever"
          @answered="updateObject"
          :value="tbData.fever"
          type="yes/no"
        ></question>

        <question
          :index="3"
          question="Have you been sweating at night (even when it is not hot)?"
          radioName="sweats"
          dataProperty="sweats"
          @answered="updateObject"
          :value="tbData.nightSweats"
          type="yes/no"
        ></question>

        <question
          :index="4"
          question="Have you suddenly lost weight (more than 1.5 kg in a month) without trying?"
          radioName="weight"
          dataProperty="weight"
          @answered="updateObject"
          :value="tbData.weightLoss"
          type="yes/no"
        ></question>

        <question
          :index="5"
          question="Have you been in contact with anyone else with TB?"
          radioName="contact"
          dataProperty="contact"
          @answered="updateObject"
          :value="tbData.contact"
          type="yes/no"
        ></question>

        <b-field label="STI Assessment" style="padding-top:15px;"></b-field>
        <b-field
          ><p id="info" @click="showStiInfo()">
            {{ stiInfo }}
          </p></b-field
        >
        <b-field
          style="color:$primary; width: device-width"
          v-if="stiInfoVisible"
          ><p>{{ stiInfoMessage }}</p></b-field
        >
        <question
          :index="1"
          question="Do you suffer from unexplained lower abdominal pain (pain in the area below your bellybutton)?"
          radioName="abdominal"
          dataProperty="abdominal"
          @answered="updateObject"
          :value="stiData.abdominalPain"
          type="yes/no"
        ></question>
        <question
          :index="2"
          question="Do you experience abnormal genital discharge?"
          radioName="discharge"
          dataProperty="discharge"
          @answered="updateObject"
          :value="stiData.discharge"
          type="yes/no"
        ></question>
        <question
          :index="3"
          question="Do you have any genital sores, ulcers or warts?"
          radioName="sores"
          dataProperty="sores"
          @answered="updateObject"
          :value="stiData.genitalSores"
          type="yes/no"
        ></question>
        <question
          :index="4"
          question="Do you experience burning, pain or blood when urinating (going to the toilet)?"
          radioName="urinepain"
          dataProperty="urinepain"
          @answered="updateObject"
          :value="stiData.urinationPain"
          type="yes/no"
        ></question>
        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import Question from "../ui/Question.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    Question,
    TheNavButtons,
  },
  data() {
    return {
      visible: true,
      resultPresent: "",
      isLoading: false,
      continue: false,
      hivInfoVisible: false,
      stiInfoVisible: false,
      tbInfoVisible: false,
      tbInfo: "more information",
      stiInfo: "more information",
      tbInfoMessage:
        "TB is a contagious disease that spreads through the air.  People who are sick with TB in their lungs are infectious, and when they cough, sneeze, talk or spit, they propel TB germs into the air. Other people become infected by breathing in the air containing these germs. TB and HIV form a lethal combination, each speeding the other's progress.  HIV weakens the immune system.  Someone who is HIV-positive and infected with TB is many times more likely to become sick with TB than someone infected with TB who is HIV-negative.  TB is a leading cause of death among people who are HIV-positive.",
      stiInfoMessage:
        "An STI (sexually transmitted infection) is an infection passed from one person to another person through sexual contact. An infection is when a bacteria, virus, or parasite enters and grows in or on your body. STIs are also called sexually transmitted diseases, or STDs. Some STIs can be cured and some STIs can be controlled but not cured.",
      hivInfo: "more information",
      hivInfoMessage:
        "Depending on your sexual behaviour, you may fall either into a Low or a High Risk category for contracting HIV. Knowing your HIV status and regular HIV testing empowers you to protect yourself. If you have tested HIV Positive and not yet on treatment (ARVs), we can help you access treatment. If you have not tested for HIV before CareWorks can help you find testing facilities.",
      hivData: {
        referenceNum: "",
        hadTest: "",
        testResult: "",
        onMedication: "",
        recentTest: "",
        hivScore: null,
        riskCat: "",
        message: "",
        notes: "",
      },
      tbData: {
        referenceNum: "",
        persistentCough: "",
        fever: "",
        nightSweats: null,
        weightLoss: "",
        contact: "",
        tbScore: null,
        riskCat: "",
        message: "",
        notes: "",
      },
      stiData: {
        referenceNum: "",
        abdominalPain: "",
        discharge: "",
        genitalSores: "",
        urinationPain: "",
        stiScore: null,
        riskCat: "",
        message: "",
        notes: "",
      },
    };
  },

  methods: {
    ...mapGetters(["getHIVData", "getSTIData", "getTBData"]),
    ...mapActions(["saveHIVData", "saveSTIData", "saveTBData"]),

    async submitHIVData() {
      const ref = this.$store.getters.getRefNum;
      this.hivData.referenceNum = ref;
      this.tbData.referenceNum = ref;
      this.stiData.referenceNum = ref;
      this.resultPresent = this.computeSelectType;
      if (this.checkResultIsPresent) {
        await this.computeHIVrisk();
        await this.computeTBrisk();
        await this.computeSTIrisk();
        this.$store.dispatch("saveHIVData", this.hivData);
        this.$store.dispatch("saveTBData", this.tbData);
        this.$store.dispatch("saveSTIData", this.stiData);

        this.saveHIVtoDB(this.hivData);
        this.saveTBtoDB(this.tbData);
        this.saveSTItoDB(this.stiData);
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Please fill in all required fields.",
          type: "is-danger",
        });
      }
    },
    saveHIVtoDB(obj) {
      this.isLoading = true; //set to loading when first update called and then set to false when last reponse is returned
      QueryService.update_withHIV(obj)
        .then(() => {
          this.successToast("saved HIV data", 2000);
        })
        .catch((error) => {
          console.log(error);
          this.errorToast("error saving HIV data", 4000);
        });
    },
    saveTBtoDB(obj) {
      QueryService.update_withTB(obj)
        .then(() => {
          this.successToast("saved TB data", 2000);
        })
        .catch((err) => {
          console.log(err);
          this.errorToast("error saving TB data", 4000);
        });
    },
    saveSTItoDB(obj) {
      QueryService.update_withSTI(obj)
        .then(() => {
          this.successToast("saved STI data", 2000);
          this.isLoading = false;
          this.continue = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorToast("error saving STI data", 4000);
          this.isLoading = false;
        });
    },

    async computeHIVrisk() {
      var score = 0;
      if (this.hivData.hadTest == "no") score += 2;
      else {
        //if user has had a test
        if (this.hivData.testResult == "positive") {
          if (this.hivData.onMedication == "no") score += 3;
        } else {
          //if test was negative or otherwise
          score++;
          if (this.hivData.recentTest == "no") score++; //test was not in the last 6 months
        }
      }

      if (score >= 3) this.hivData.riskCat = "high risk";
      else if (score == 2) this.hivData.riskCat = "moderate risk";
      else this.hivData.riskCat = "low risk";
      this.hivData.hivScore = score;
      return await this.getActionMessage("hiv", score, this.hivData.riskCat);
    },
    async computeTBrisk() {
      var score = 0;
      if (this.tbData.persistentCough == "yes") score++;
      if (this.tbData.fever == "yes") score++;
      if (this.tbData.nightSweats == "yes") score++;
      if (this.tbData.weightLoss == "yes") score++;
      if (this.tbData.contact == "yes") score++;

      if (score >= 1) this.tbData.riskCat = "high risk";
      else this.tbData.riskCat = "low risk";
      this.tbData.tbScore = score;

      if (score >= 1) {
        return await this.getActionMessage("tb", ">=1", this.tbData.riskCat);
      } else {
        return await this.getActionMessage("tb", score, this.tbData.riskCat);
      }
    },
    async computeSTIrisk() {
      var score = 0;
      if (this.stiData.abdominalPain == "yes") score++;
      if (this.stiData.discharge == "yes") score++;
      if (this.stiData.sores == "yes") score++;
      if (this.stiData.urinationPain == "yes") score++;

      if (score >= 1) this.stiData.riskCat = "high risk";
      else this.stiData.riskCat = "low risk";

      this.stiData.stiScore = score;
      if (score >= 1) {
        return await this.getActionMessage("sti", ">=1", this.stiData.riskCat);
      } else {
        return await this.getActionMessage("sti", score, this.stiData.riskCat);
      }
    },
    getActionMessage(section, score, cat) {
      let resp = QueryService.get_ActionMessage(section, score, cat)
        .then((res) => {
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            if (section == "hiv") {
              if (this.gender == "male")
                this.hivData.message = res.data[0].message_male;
              else this.hivData.message = res.data[0].message_female;
              this.hivData.nurse_task = res.data[0].nurse_task;
              this.hivData.screening_type = res.data[0].screening_type;
              if (res.data[0].notes != "")
                this.hivData.notes = res.data[0].notes;
              return "success";
            } else if (section == "tb") {
              if (this.gender == "male")
                this.tbData.message = res.data[0].message_male;
              else this.tbData.message = res.data[0].message_female;
              this.tbData.nurse_task = res.data[0].nurse_task;
              this.tbData.screening_type = res.data[0].screening_type;
              if (res.data[0].notes != "")
                this.tbData.notes = res.data[0].notes;
              return "success";
            } else {
              if (this.gender == "male")
                this.stiData.message = res.data[0].message_male;
              else this.stiData.message = res.data[0].message_female;
              this.stiData.nurse_task = res.data[0].nurse_task;
              this.stiData.screening_type = res.data[0].screening_type;
              if (res.data[0].notes != "")
                this.stiData.notes = res.data[0].notes;
              return "success";
            }
          }
        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
      return resp;
    },
    showHivInfo() {
      this.hivInfoVisible = !this.hivInfoVisible;
      if (this.hivInfoVisible) {
        this.hivInfo = "hide";
      } else {
        this.hivInfo = "more information";
      }
    },
    showTbInfo() {
      this.tbInfoVisible = !this.tbInfoVisible;
      if (this.tbInfoVisible) {
        this.tbInfo = "hide";
      } else {
        this.tbInfo = "more information";
      }
    },
    showStiInfo() {
      this.stiInfoVisible = !this.stiInfoVisible;
      if (this.stiInfoVisible) {
        this.stiInfo = "hide";
      } else {
        this.stiInfo = "more information";
      }
    },
    clear() {
      this.hivData = {
        referenceNum: "",
        hadTest: "",
        testResult: "",
        onMedication: "",
        recentTest: "",
        hivScore: null,
        riskCat: "",
        notes: "",
      };
      this.tbData = {
        referenceNum: "",
        persistentCough: "",
        fever: "",
        nightSweats: null,
        weightLoss: "",
        contact: "",
        tbScore: null,
        riskCat: "",
        notes: "",
      };
      this.stiData = {
        referenceNum: "",
        abdominalPain: "",
        discharge: "",
        genitalSores: "",
        urinationPain: "",
        stiScore: null,
        riskCat: "",
        notes: "",
      };
    },
    updateObject(answer, dataProp) {
      switch (dataProp) {
        case "hivTest":
          this.hivData.hadTest = answer;
          break;
        case "hivTestResult":
          this.hivData.testResult = answer;
          break;
        case "recentTest":
          this.hivData.recentTest = answer;
          break;
        case "arv":
          this.hivData.onMedication = answer;
          break;
        case "cough":
          this.tbData.persistentCough = answer;
          break;
        case "fever":
          this.tbData.fever = answer;
          break;
        case "sweats":
          this.tbData.nightSweats = answer;
          break;
        case "weight":
          this.tbData.weightLoss = answer;
          break;
        case "contact":
          this.tbData.contact = answer;
          break;
        case "abdominal":
          this.stiData.abdominalPain = answer;
          break;
        case "discharge":
          this.stiData.discharge = answer;
          break;
        case "sores":
          this.stiData.genitalSores = answer;
          break;
        case "urinepain":
          this.stiData.urinationPain = answer;
          break;
      }
    },
  },
  computed: {
    checkResultIsPresent() {
      if (this.hivData.hadTest == "yes") {
        if (this.hivData.testResult != "") {
          return true;
        } else {
          return false;
        }
      }
      if (this.hivData.hadTest == "no") {
        return true;
      }
      return true;
    },
    computeSelectType() {
      if (this.checkResultIsPresent) return "";
      else return "is-danger";
    },
    lessThan6Months() {
      return (
        (this.hivData.testResult == "negative" ||
          this.hivData.testResult == "rather not say") &&
        this.hivData.hadTest == "yes"
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("saveHIVData", this.hivData);
    this.$store.dispatch("saveTBData", this.tbData);
    this.$store.dispatch("saveSTIData", this.stiData);
    next();
  },
  watch: {
    continue() {
      if (this.continue == true) {
        //will push to next page when completed this
        this.continue = false;
        this.$store.dispatch("setHivFinished", true);
        this.$router.push({ name: "MentalHealth" });
      }
    },
  },

  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.hiv;
    }

    const hivObject = this.getHIVData();
    const tbObject = this.getTBData();
    const stiObject = this.getSTIData();
    if (hivObject) this.hivData = hivObject;
    if (tbObject) this.tbData = tbObject;
    if (stiObject) this.stiData = stiObject;
  },
};
</script>
