<template>
  <div  id="">
  <section class="is-fullheight">
    <login></login>
  </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'Home',
  components: {
    Login,
  }
}
</script>

<style scoped>
/* #wrapper {
  height: 100%;
  background-image: url("../assets/back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
div {
    height: 100vh;
}
</style> 



