<template>
  <section>
    <the-admin-nav-bar
      :value="activeTab"
      @tabChanged="updateTab"
    ></the-admin-nav-bar>

    <div class="has-text-left container" style="padding: 0px 0px 100px 0px;">
      <campaign-editor v-if="activeTab == 'Campaign'"></campaign-editor>
      <add-user v-if="activeTab == 'AddUser'"></add-user>
      <risk-editor v-if="activeTab == 'Risk'"></risk-editor>
      <div v-if="activeTab == 'Query'">
        <b-field grouped>
          <b-field label="id number">
            <b-input size="is-small" type="text" v-model.trim="idNumber"
          /></b-field>
          <b-field label="campaign code">
            <b-input size="is-small" type="text" v-model.trim="campaign"
          /></b-field>
          <b-field>
            <b-button
              outlined
              type="is-primary"
              @click="checkAndLoad"
              size="is-small"
              style="margin-top:30px;"
              rounded
              >query</b-button
            ></b-field
          >
          <b-field>
            <b-button
              outlined
              type="is-primary"
              @click="exportCSV"
              size="is-small"
              style="margin-top:30px;"
              rounded
              >export to csv</b-button
            >
          </b-field>
        </b-field>
        <b-table
          :data="userDetails"
          :columns="columns"
          :sticky-header="stickyHeaders"
          height="auto"
          size="is-small"
          :loading="isLoading"
          :checked-rows.sync="checkedRows"
          checkable
          sticky-checkbox
        ></b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { QueryService } from "../../services/query.service";
import TheAdminNavBar from "../ui/TheAdminNavBar.vue";
import RiskEditor from "../admin/RiskEditor.vue";
import CampaignEditor from "../admin/CampaignEditor.vue";
import AddUser from "../admin/AddUser.vue";

export default {
  components: { TheAdminNavBar, RiskEditor, CampaignEditor, AddUser },

  data() {
    return {
      activeTab: "Query",
      age: null,
      idNumber: "",
      campaign: "",
      isLoading: false,
      checkedRows: [],
      userDetails: [], //this will store userObjects from the database in an array
      columns: [
        {
          field: "reference_num",
          label: "Ref",
          width: "10",
          headerClass: "is-sticky-column-one",
          cellClass: "is-sticky-column-one",
        },
        { field: "fullname", label: "fullname", sticky: true, width: "150" },
        { field: "id_number", label: "id" },
        { field: "cellphone", label: "cellphone" },
        { field: "work_number", label: "work number" },
        { field: "age", label: "age" },
        { field: "age_band", label: "age band" },
        { field: "campaign_code", label: "campaign" },
        // { field: "contact_consent", label: "contact consent" },
        // { field: "pharmacy_consent", label: "pharmacy consent" },
        { field: "cardio_data.stroke_or_heart_attack", label: "stroke/heart" },
        { field: "cardio_data.high_cholestrol", label: "cholestrol" },
        { field: "cardio_data.smoker", label: "smoker" },
        { field: "cardio_data.cardio_score", label: "cardio score" },
      ],
      stickyHeaders: true,
    };
  },
  methods: {
    updateTab(tabValue) {
      this.activeTab = tabValue;
    },
    async exportCSV() {
      if (this.checkedRows.length == 0) {
        this.errorToast("please select records to export");
      } else {
        // var headers = this.getHeaders(); //headers located in helpers file

        let res = await QueryService.get_CSV(this.checkedRows);
        var csv = res.data.csv;
        var adhocCvs = res.data.adhoc

        this.saveCsvFile("hra_user_data.csv",csv)
        this.saveCsvFile("hra_adhoc_data.csv",adhocCvs)
    
        ///this.exportCSVFile(this.checkedRows, "exportedFile"); //JSON.stringify(this.checkedRows[0]));
        this.checkedRows = [];
      }
    },
    saveCsvFile(filename, csv){
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    },
    checkAndLoad() {
      this.userDetails = [];
      if (this.campaign != "" && this.idNumber != "")
        this.load(this.idNumber, this.campaign);
      else if (this.idNumber == "") this.load(null, this.campaign);
      else if (this.campaign == "") this.load(this.idNumber, null);
      else this.load();
    },
    load(idNum = null, campaign = null) {
      this.isLoading = true;
      QueryService.get_UserDetails(idNum, campaign)
        .then((res) => {
          this.userDetails = res.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    exitAdminPortal() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
