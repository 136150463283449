<template>
  <section>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 150px;
        padding-left: 20px;
      "
    >
      <b-field grouped>
        <p class="title is-5">Cancer Prevention</p>
      </b-field>
      <b-field
        ><p id="info" @click="showCancerInfo()">
          {{ cancerInfo }}
        </p></b-field
      >
      <b-field
        style="color: $primary; width: device-width"
        v-if="cancerInfoVisible"
        ><p>{{ cancerInfoText }}</p></b-field
      >
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="Cancer"
        @clearFields="clear()"
      ></the-nav-buttons>
      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to complete this section of
        this assessment
      </b-message>
      <form
        @submit.prevent="submitCancerData"
        style="padding-top: 15px"
        v-if="visible"
      >
        <b-message
          type="is-primary"
          aria-close-label="Close message"
          v-if="(gender == 'male' && !maleOver50) || femaleUnder18"
        >
          This section is not relevant to your age, continue to the next section.
        </b-message>

        <question
          v-if="maleOver50"
          :index="1"
          question="Have you ever had a prostate examination or PSA blood test done?"
          radioName="prostate"
          dataProperty="prostate"
          @answered="updateObject"
          :value="prostateCancerData.prostateExam"
          type="yes/no"
        ></question>

        <question
          :index="1"
          v-if="femaleOver18"
          question="Have you ever had pap smear?"
          radioName="paps"
          dataProperty="papsmear"
          @answered="updateObject"
          :value="cervicalCancerData.papSmear"
          type="yes/no"
        ></question>

        <question
          v-if="maleOver50 || femaleOver50"
          :index="2"
          question="Have you had a colonoscopy to screen for Colorectal cancer?"
          radioName="colonoscopy"
          dataProperty="colonoscopy"
          @answered="updateObject"
          :value="colorectalCancerData.colonoscopyCompleted"
          type="yes/no"
        ></question>

        <question
          :index="femaleOver40 ? 3 : 2"
          v-if="femaleOver40"
          question="Have you been screened for Breast Cancer (examination and/or Ultrasound and/or Mammogram)?"
          radioName="titties"
          dataProperty="breast_cancer"
          @answered="updateObject"
          :value="breastCancerData.breastCancerScreening"
          type="yes/no"
        ></question>

        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </section>
</template>

<script>
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import question from "../ui/Question.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    question,
    TheNavButtons,
  },
  data() {
    return {
      cancerInfoVisible: false,
      cancerInfo: "more information",
      cancerInfoText: "test",
      infoMale:
        "Prostate cancer is cancer that starts in the prostate gland, a small, walnut-sized part of your body that is part of a man's reproductive system. The prostate is found below the bladder and in front of the rectum.Prostate cancer is the third most common cause of death from cancer in men. For men over the age of 75, it is the most common cause of death from cancer.It is recommended that men 50 years and older should have a rectal examination and/or a Prostate Specific Antigen (PSA) blood test every 2 years.",
      infoFemale:
        "Cervical cancer is cancer that develops in the cervix, the lower part of the womb that opens into the vagina. Every woman is at risk of cervical cancer as it is not a disease that is hereditary. About 80% of cervical cancer is caused by certain types of virus called Human Papilloma Virus (HPV).  This virus is transmitted during sex. This is one of the cancers that can be easily detected and prevented.  It can also be treated with great success if caught early. Monitoring is done by having a PAP test to examine the cells of the cervix. It is recommended that women who are sexually active should have a PAP test every 2 years. Women who are HIV Positive are particularly at risk of developing cervical cancer and should be monitored yearly.",
      visible: true,
      age: null,
      gender: null,
      isLoading: false,
      continue: false,
      allCancerData: null,
      cancerData: {
        referenceNum: "",
        prostateExam: "",
        papSmear: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
      cervicalCancerData: {
        referenceNum: "",
        papSmear: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
      prostateCancerData: {
        referenceNum: "",
        prostateExam: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
      breastCancerData: {
        referenceNum: "",
        breastCancerScreening: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
      colorectalCancerData: {
        referenceNum: "",
        colonoscopyCompleted: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
    };
  },
  computed: {
    maleOver50() {
      if (this.gender == "male" && this.age >= 50) return true;
      return false;
    },
    femaleOver40() {
      if (this.gender == "female" && this.age >= 40) return true;
      return false;
    },
    femaleOver18() {
      if (this.gender == "female" && this.age >= 18) return true;
      return false;
    },
    femaleUnder18() {
      if (this.gender == "female" && this.age < 18) return true;
      return false;
    },
    femaleOver50() {
      if (this.gender == "female" && this.age >= 50) return true;
      return false;
    },
  },
  methods: {
    clear() {
      this.cancerData = {
        referenceNum: "",
        prostateExam: "",
        papSmear: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        notes: "",
      };
      (this.cervicalCancerData = {
        referenceNum: "",
        papSmear: "",
        cancerScore: null,
        riskCat: null,
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      }),
        (this.prostateCancerData = {
          referenceNum: "",
          prostateExam: "",
          cancerScore: null,
          riskCat: null,
          message: "",
          nurse_task: "",
          screening_type: "",
          notes: "",
        }),
        (this.breastCancerData = {
          referenceNum: "",
          breastCancerScreening: "",
          cancerScore: null,
          riskCat: null,
          message: "",
          nurse_task: "",
          screening_type: "",
          notes: "",
        }),
        (this.colorectalCancerData = {
          referenceNum: "",
          colonoscopyCompleted: "",
          cancerScore: null,
          riskCat: null,
          message: "",
          nurse_task: "",
          screening_type: "",
          notes: "",
        });
    },
    showCancerInfo() {
      if (this.gender == "male") {
        this.cancerInfoText = this.infoMale;
      } else {
        this.cancerInfoText = this.infoFemale;
      }
      this.cancerInfoVisible = !this.cancerInfoVisible;
      if (this.cancerInfoVisible) {
        this.cancerInfo = "hide";
      } else {
        this.cancerInfo = "more information";
      }
    },
    async submitCancerData() {
      var refNum = this.$store.getters.getRefNum;
      this.cancerData.referenceNum = refNum;
      this.cervicalCancerData.referenceNum = refNum;
      this.prostateCancerData.referenceNum = refNum;
      this.colorectalCancerData.referenceNum = refNum;
      this.breastCancerData.referenceNum = refNum;

      this.allCancerData = {
        legacy: this.cancerData,
        cervical: this.cervicalCancerData,
        prostate: this.prostateCancerData,
        colorectal: this.colorectalCancerData,
        breast: this.breastCancerData,
      };

      await this.computeRisk();
      this.$store.dispatch("saveCancerData", this.allCancerData);
      this.saveCancerToDB(this.allCancerData);
    },
    saveCancerToDB(obj) {
      this.isLoading = true;
      QueryService.update_withCancer(obj)
        .then((response) => {
          console.log(response);
          this.successToast("saved cancer data", 2000);
          this.isLoading = false;
          this.$store.dispatch("setCancerFinished", true);
          this.continue = true;
        })
        .catch((error) => {
          console.log(error);
          this.errorToast("error saving cancer data", 4000);
          this.isLoading = false;
        });
    },
    async getActionMessage(score, cat, category) {
      let resp = QueryService.get_ActionMessage(category, score, cat)
        .then((res) => {
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            return res.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
      return resp;
    },
    async computeRisk() {
      var score = 0;

      if (
        (this.maleOver50 || this.femaleOver50) &&
        this.colorectalCancerData.colonoscopyCompleted == "no"
      ) {
        this.colorectalCancerData.cancerScore = 1;
        this.colorectalCancerData.riskCat = "high risk";
      } else {
        this.colorectalCancerData.cancerScore = 0;
        this.colorectalCancerData.riskCat = "low risk";
      }

      if (this.maleOver50 && this.prostateCancerData.prostateExam == "no") {
        this.prostateCancerData.cancerScore = 1;
        this.prostateCancerData.riskCat = "high risk";
      } else {
        this.prostateCancerData.cancerScore = 0;
        this.prostateCancerData.riskCat = "low risk";
      }

      if (
        this.gender == "female" &&
        this.age > 30 &&
        this.cervicalCancerData.papSmear == "no"
      ) {
        this.cervicalCancerData.cancerScore = 1;
        this.cervicalCancerData.riskCat = "high risk";
      } else {
        this.cervicalCancerData.cancerScore = 0;
        this.cervicalCancerData.riskCat = "low risk";
      }

      if (
        this.femaleOver40 &&
        this.breastCancerData.breastCancerScreening == "no"
      ) {
        this.breastCancerData.cancerScore = 1;
        this.breastCancerData.riskCat = "high risk";
      } else {
        this.breastCancerData.cancerScore = 0;
        this.breastCancerData.riskCat = "low risk";
      }

      if (this.gender == "female") {
        var cervicalRes = await this.getActionMessage(
          String(score),
          this.cervicalCancerData.riskCat,
          "cervical cancer"
        );
        var colonRes = await this.getActionMessage(
          String(score),
          this.colorectalCancerData.riskCat,
          "colorectal cancer"
        );
        var breastRes = await this.getActionMessage(
          String(score),
          this.breastCancerData.riskCat,
          "breast cancer"
        );

        this.cervicalCancerData.message = cervicalRes.message_female;
        this.cervicalCancerData.nurse_task = cervicalRes.nurse_task;
        this.cervicalCancerData.screening_type = cervicalRes.screening_type;

        this.colorectalCancerData.message = colonRes.message_female;
        this.colorectalCancerData.nurse_task = colonRes.nurse_task;
        this.colorectalCancerData.screening_type = colonRes.screening_type;

        this.breastCancerData.message = breastRes.message_female;
        this.breastCancerData.nurse_task = breastRes.nurse_task;
        this.breastCancerData.screening_type = breastRes.screening_type;
      } else {
        var prostateRes = await this.getActionMessage(
          String(score),
          this.prostateCancerData.riskCat,
          "prostate cancer"
        );
        colonRes = await this.getActionMessage(
          String(score),
          this.colorectalCancerData.riskCat,
          "colorectal cancer"
        );

        this.prostateCancerData.message = prostateRes.message_male;
        this.prostateCancerData.nurse_task = prostateRes.nurse_task;
        this.prostateCancerData.screening_type = prostateRes.screening_type;

        this.colorectalCancerData.message = colonRes.message_male;
        this.colorectalCancerData.nurse_task = colonRes.nurse_task;
        this.colorectalCancerData.screening_type = colonRes.screening_type;
      }
    },
    updateObject(answer, dataProp) {
      if (dataProp == "prostate") this.prostateCancerData.prostateExam = answer;
      if (dataProp == "papsmear") this.cervicalCancerData.papSmear = answer;
      if (dataProp == "breast_cancer")
        this.breastCancerData.breastCancerScreening = answer;
      if (dataProp == "colonoscopy")
        this.colorectalCancerData.colonoscopyCompleted = answer;
    },
  },
  watch: {
    continue() {
      if (this.continue == true) this.$router.push({ name: "HIV" });
      this.continue = false;
    },
  },

  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.cancer;
    }

    this.age = this.$store.getters.getAge;
    this.gender = this.$store.getters.getGender;
    const cancerObject = this.$store.getters.getCancerData;
    console.log(cancerObject);
    if (cancerObject) {
      this.allCancerData = cancerObject;
      this.cancerData = cancerObject.legacy;
      this.cervicalCancerData = cancerObject.cervical;
      this.prostateCancerData = cancerObject.prostate;
      this.breastCancerData = cancerObject.breast;
      this.colorectalCancerData = cancerObject.colorectal;
    }
    if (this.gender == "male" && !this.maleOver50 && !this.visible) {
      this.$store.dispatch("setCancerFinished", true);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("saveCancerData", this.allCancerData);
    next();
  },
};
</script>
