<template>
  <section>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field grouped>
        <p class="title is-5">Diabetes</p>
      </b-field>
      <b-field
            ><p id="info" @click="showDiabInfo()">
              {{ diabInfo }}
            </p></b-field
          >
          <b-field style="color:$primary; width: device-width" v-if="diabInfoVisible"
            ><p>{{ diabInfoText }}</p></b-field
          > 
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="Diabetes"
        @clearFields="clear()"
      ></the-nav-buttons>
       <b-message
          type="is-primary"
          aria-close-label="Close message"
          v-if="!visible"
        >
          You do not need to complete this section of this assessment
        </b-message>
      <form @submit.prevent="submitDiabetesData()" style="padding-top: 15px" v-if="visible">
        <question
          :index="1"
          question="Have you ever been diagnosed with diabetes?"
          radioName="diagnosed"
          dataProperty="diagnosed"
          @answered="updateObject"
          :value="diabetesData.diagnosed"
          type="yes/no"
        ></question>

        <question
          :index="2"
          v-if="diabetesData.diagnosed == 'yes'"
          question="Are you on medication for diabetes?"
          radioName="meds"
          dataProperty="meds"
          @answered="updateObject"
          :value="diabetesData.onMedication"
          type="yes/no"
        ></question>

        <!--only show these questions if the user has not been diagnosed with diabetes-->
        <div v-if="diabetesData.diagnosed == 'no'">
          <question
            :index="2"
            question="Have you ever been told that your blood sugar is high?"
            radioName="sugar"
            dataProperty="bloodsugar"
            @answered="updateObject"
            :value="diabetesData.toldYouHaveDiabetes"
            type="yes/no"
          ></question>

          <b-field label="Do you have any of the following symptoms?" style="padding-top:15px;"></b-field>
          <div style="padding-left: 20px; padding-bottom: 20px">
            <question
              :index="3.1"
              question="Are you urinating (going to the toilet) more than you used to (not due to other medication)?"
              radioName="symp"
              dataProperty="urinate"
              @answered="updateObject"
              :value="diabetesData.symptoms.excessiveUrination"
              type="yes/no"
            ></question>

            <question
              :index="3.2"
              question="Do you get more thirsty than you used to?"
              radioName="symp2"
              dataProperty="thirsty"
              @answered="updateObject"
              :value="diabetesData.symptoms.excessiveThirst"
              type="yes/no"
            ></question>

            <question
              :index="3.3"
              question="Do you have a family history of diabetes?"
              radioName="symp5"
              dataProperty="history"
              @answered="updateObject"
              :value="diabetesData.symptoms.familyHistory"
              type="yes/no"
            ></question>

            <question
              :index="3.4"
              question="Are your wounds (cuts and scrapes) taking longer to heal than they used to?"
              radioName="symp3"
              dataProperty="wounds"
              @answered="updateObject"
              :value="diabetesData.symptoms.wounds"
              type="yes/no"
            ></question>

            <question
              :index="3.5"
              question="Do you feel tired more than usual?"
              radioName="symp4"
              dataProperty="tiredness"
              @answered="updateObject"
              :value="diabetesData.symptoms.excessiveTiredness"
              type="yes/no"
            ></question>
          </div>

          <question
            :index="4"
            v-if="gender == 'female'"
            question=" If you have been pregnant before, were you ever diagnosed with diabetes during your pregnancy?"
            radioName="pregnancy"
            dataProperty="pregnant"
            @answered="updateObject"
            :value="diabetesData.duringPregnancy"
            type="yes/no"
          ></question>
        </div>
        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </section>
</template>

<script>
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import Question from "../ui/Question.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    Question,
    TheNavButtons,
  },
  data() {
    return {
      diabInfo:"more information",
      diabInfoText:"Diabetes is also called sugar sickness. It is the condition when your body is unable to use sugar correctly to provide the energy you need for normal healthy living. Chronic high blood sugar levels damage small arteries in vital organs. This leads to heart disease and heart failure, kidney failure, eye disease and cataracts, damage to nerves and stroke.",
      diabInfoVisible:false,
      visible:true,
      age: null,
      gender: null,
      isLoading: false,
      continue: false,
      diabetesData: {
        referenceNum: "",
        diagnosed: "",
        onMedication: "",
        toldYouHaveDiabetes: "",
        symptoms: {
          excessiveUrination: "",
          excessiveThirst: "",
          familyHistory: "",
          wounds: "",
          excessiveTiredness: "",
        },
        diabetesScore: null,
        riskCat: "",
        duringPregnancy: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
    };
  },
  methods: {
    clear(){
      this.diabetesData ={
        referenceNum: "",
        diagnosed: "",
        onMedication: "",
        toldYouHaveDiabetes: "",
        symptoms: {
          excessiveUrination: "",
          excessiveThirst: "",
          familyHistory: "",
          wounds: "",
          excessiveTiredness: "",
        },
        diabetesScore: null,
        riskCat: "",
        duringPregnancy: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      }
    },
    showDiabInfo(){
      this.diabInfoVisible = !this.diabInfoVisible;
      if(this.diabInfoVisible){
        this.diabInfo="hide"
      }else{
        this.diabInfo="more information"
      }
    },
    async submitDiabetesData() {
      console.log(this.diabetesData);
      this.diabetesData.referenceNum = this.$store.getters.getRefNum;
      let response = await this.computeRisk();
      console.log(response, "RES");
      this.$store.dispatch("saveDiabetesData", this.diabetesData);
      this.saveDiabetesToDB(this.diabetesData);
    },
    saveDiabetesToDB(obj) {
      this.isLoading = true;
      QueryService.update_withDiabetes(obj)
        .then((response) => {
          this.successToast("saved diabetes data", 2000);
          console.log(response);
          this.isLoading = false;
          this.continue = true;
           this.$store.dispatch('setDiabetesFinished', true)
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.errorToast("error saving diabetes data", 4000);
        });
    },

    async computeRisk() {
      var score = 0;
      if (this.diabetesData.diagnosed == "no") {
        score++;
        //if not already diagnosed
        if (this.diabetesData.toldYouHaveDiabetes == "yes") score++;
        if (this.diabetesData.symptoms.excessiveUrination == "yes") score += 2;
        if (this.diabetesData.symptoms.excessiveThirst == "yes") score += 2;
        if (this.diabetesData.symptoms.excessiveTiredness == "yes") score++;
        if (this.diabetesData.symptoms.familyHistory == "yes") score += 2;
        if (this.diabetesData.symptoms.wounds == "yes") score++;
        if (
          this.diabetesData.duringPregnancy == "yes" &&
          this.gender == "female"
        ) {
          score += 8;
        }
        switch (this.gender) {
          case "male":
            score++;
            if (this.age >= 45) {
              score++;
            }
            break;
          case "female":
            score++;
            if (this.age >= 65) {
              score++;
            }
            break;
        } //end switch

        if (score >= 8) this.diabetesData.riskCat = "high risk";
        else if (score == 6 || score == 7)
          this.diabetesData.riskCat = "pre diabetic";
        else this.diabetesData.riskCat = "low to moderate risk"; //score <6
      } //end if
      else {
        //i.e. user has been diagnosed with diabetes
        if (this.diabetesData.onMedication == "yes") {
          this.diabetesData.riskCat = "low to moderate risk";
        } else {
          //if diagnosed but not on meds
          this.diabetesData.riskCat = "pre diabetic"; //ASK KIM ABOUT THESE RISK CATEGORIES
          score += 7; //how much should i add to score? maybe add to notes at this point?
        }
      }
      this.diabetesData.diabetesScore = score;
      console.log(score, this.diabetesData.riskCat);

      let res = "";
      if (score >= 8) {
        res = await this.getActionMessage(">=8", this.diabetesData.riskCat);
      } else if (score == 6 || score == 7) {
        res = await this.getActionMessage("6 or 7", this.diabetesData.riskCat);
      } else {
        res = await this.getActionMessage("<6", this.diabetesData.riskCat);
      }
      return res;
    },

    getActionMessage(score, cat) {
      //var stat = "";
      const stat = QueryService.get_ActionMessage("diabetes", score, cat)
        .then((res) => {
          console.log(res);
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            if (this.gender == "male")
              this.diabetesData.message = res.data[0].message_male;
            else this.diabetesData.message = res.data[0].message_female;
            this.diabetesData.nurse_task = res.data[0].nurse_task;
            this.diabetesData.screening_type = res.data[0].screening_type;
            if (res.data[0].notes != "")
              this.diabetesData.notes = res.data[0].notes;
            //stat = 'sucess';

            return "success";
          }
        })
        .catch((error) => {
          console.log(error);
          //stat = 'error';
          return "error";
        });
      return stat;
    },

    updateObject(answer, dataProp) {
      switch (dataProp) {
        case "tiredness":
          this.diabetesData.symptoms.excessiveTiredness = answer;
          break;
        case "diagnosed":
          this.diabetesData.diagnosed = answer;
          break;
        case "bloodsugar":
          this.diabetesData.toldYouHaveDiabetes = answer;
          break;
        case "urinate":
          this.diabetesData.symptoms.excessiveUrination = answer;
          break;
        case "thirsty":
          this.diabetesData.symptoms.excessiveThirst = answer;
          break;
        case "wounds":
          this.diabetesData.symptoms.wounds = answer;
          break;
        case "pregnant":
          this.diabetesData.duringPregnancy = answer;
          break;
        case "history":
          this.diabetesData.symptoms.familyHistory = answer;
          break;
        case "meds":
          this.diabetesData.onMedication = answer;
          break;
      }
    },
  },
  watch: {
    continue() {
      if (this.continue == true) {
        this.continue == false;
        this.$router.push({ name: "Numbers" });
      }
    },
  },

  created() {
     var visibleSections = this.$store.getters.getVisibleSections
    if(visibleSections != null){
      this.visible = visibleSections.diabetes
    }
    
    this.age = this.$store.getters.getAge;
    this.gender = this.$store.getters.getGender;
    const diabObject = this.$store.getters.getDiabetesData;
    if (diabObject) {
      this.diabetesData = diabObject;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("saveDiabetesData", this.diabetesData);
    next();
  },
};
</script>
