<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field>
        <p class="title is-5">Cardiovascular</p>
      </b-field>
      <b-field
            ><p id="info" @click="showCardioInfo()">
              {{ cardioInfo }}
            </p></b-field
          >
          <b-field style="color:$primary; width: device-width" v-if="cardioInfoVisible"
            ><p>{{ cardioInfoText }}</p></b-field
          >
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="Cardio"
        @clearFields="clear()"
      ></the-nav-buttons>

      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to to complete this
        section of this assessment
      </b-message>
      <form
        @submit.prevent="submitCardioData()"
        style="padding-top: 15px"
        v-if="visible"
      >
        
        <div class="columns customQuestion">
          <div class="column is-three-quarters">
          <b-field expanded>
            <p>1. Have you ever had a stroke or a heart attack?</p>
          </b-field>
          </div>
          <div class="column">
          <b-field position="is-centered">
            <b-radio
              v-model="cardioData.strokeOrHeartAttack"
              class="has-text-right"
              name="cardio1"
              native-value="yes"
              required
              >yes</b-radio
            >
            <b-radio
              v-model="cardioData.strokeOrHeartAttack"
              name="cardio1"
              native-value="no"
              required
              >no</b-radio
            >
          </b-field>
          </div>
        </div>

       <div class="columns customQuestion">
          <div class="column is-three-quarters">
          <b-field expanded>
            <p>2. Have you ever been told that your cholesterol is high?</p>
          </b-field>
          </div>
          <div class="column">
          <b-field position="is-centered">
            <b-radio
              v-model="cardioData.highCholestrol"
              name="cardio2"
              native-value="yes"
              required
              >yes</b-radio
            >
            <b-radio
              v-model="cardioData.highCholestrol"
              name="cardio2"
              native-value="no"
              required
              >no</b-radio
            >
          </b-field>
        </div>
        </div>

      <div class="columns customQuestion">
          <div class="column is-three-quarters">
          <b-field expanded>
            <p>3. Do you smoke?</p>
          </b-field>
          </div>
          <div class="column">
          <b-field position="is-centered">
            <b-radio
              v-model="cardioData.smoker"
              name="cardio3"
              native-value="yes"
              required
              >yes</b-radio
            >
            <b-radio
              v-model="cardioData.smoker"
              name="cardio3"
              native-value="no"
              required
              >no</b-radio
            >
          </b-field>
          </div>
        </div>
        <b-field position="is-right">
        <b-button
          type="is-primary"
          outlined
          native-type="submit"
          class="submitButton"
          >submit</b-button
        >
        
        <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    TheNavButtons,
  },
  data() {
    return {
      cardioInfo:"more information",
      cardioInfoText:"These questions are designed to help us work out your risk of developing cardiovascular disease. This includes disorders of the heart and blood vessels which can lead to a heart attack, stroke or blocked arteries.This tool does not provide a diagnosis. It must be followed by a visit to your health care provider for testing and possible diagnosis of cardiovascular disease.Your risk may be underestimated if you are overweight, have an unhealthy diet and do not participate in physical exercise.",
      cardioInfoVisible:false,
      visible: true,
      age: null,
      gender: null,
      isLoading: false,
      continue: false,
      cardioData: {
        referenceNum: "", // links this data to respective user
        strokeOrHeartAttack: null,
        highCholestrol: null,
        smoker: null,
        cardiovascularScore: 0,
        riskCat: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      },
    };
  },
  computed: {},
  methods: {
    ...mapGetters(["getAge", "getGender", "getRefNum", "getCardioData"]),
    showCardioInfo(){
      this.cardioInfoVisible = !this.cardioInfoVisible
      if(this.cardioInfoVisible){
        this.cardioInfo = "hide"
      }else{
        this.cardioInfo = "more information"
      }
    },
    async submitCardioData() {
      var age = this.age;
      var gender = this.gender;
      if (!gender) {
        //if gender == null
        this.noGender = true;
      }
      if (!age) {
        this.noAge = true;
      }
      if (age && gender) {
        this.computeCardioRisk();
        this.cardioData.referenceNum = this.$store.getters.getRefNum;
        this.$store.dispatch("saveCardioData", this.cardioData);
        this.saveCardioToDB(this.cardioData);
      }
    },
    async computeCardioRisk() {
      var age = this.age;
      const gender = this.gender;
      var score = 0;
      switch (gender) {
        case "male":
          score += 1;
          if (age >= 45) {
            score += 1;
          }
          break;
        case "female":
          score += 1;
          if (age >= 65) {
            score += 1;
          }
          break;
      } //end switch statement
      if (this.cardioData.strokeOrHeartAttack == "yes") score += 1;
      if (this.cardioData.highCholestrol == "yes") score += 1;
      if (this.cardioData.smoker == "yes") score += 1;
      var res = "";
      if (score < 3) {
        this.cardioData.riskCat = "low to moderate risk";
        res = await this.getActionMessage("<3", "low to moderate risk");
      } else {
        this.cardioData.riskCat = "high risk";
        res = await this.getActionMessage(">=3", "high risk");
      }
      this.cardioData.cardiovascularScore = score;
      return res;
    },
    saveCardioToDB(obj) {
      this.isLoading = true;
      QueryService.update_withCardio(obj)
        .then((response) => {
          console.log(response);
          this.successToast("saved cardio data", 2000);
          this.isLoading = false;
          this.continue = true;
          this.$store.dispatch('setCardioFinished', true)
        })
        .catch((error) => {
          console.log(error);
          this.errorToast("error saving cardio data", 4000);
          this.isLoading = false;
        });
    },
    async getActionMessage(score, cat) {
      let resp = QueryService.get_ActionMessage("cardio", score, cat)
        .then((res) => {
          //console.log(res)
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            if (this.gender == "male")
              this.cardioData.message = res.data[0].message_male;
            else this.cardioData.message = res.data[0].message_female;
            this.cardioData.nurse_task = res.data[0].nurse_task;
            this.cardioData.screening_type = res.data[0].screening_type;
            this.cardioData.notes = this.cardioData.notes = res.data[0].notes;
            return "success";
          }
        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
      return resp;
    },
    clear() {
      this.cardioData = {
        referenceNum: "", // links this data to respective user
        strokeOrHeartAttack: null,
        highCholestrol: null,
        smoker: null,
        cardiovascularScore: 0,
        riskCat: "",
        message: "",
        nurse_task: "",
        screening_type: "",
        notes: "",
      };
    },
  },
  watch: {
    continue() {
      if (this.continue == true) {
        this.continue = false;
        this.$router.push({ name: "Hypertension" });
      }
    },
  },
  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.cardio;
    }

    this.age = this.$store.getters.getAge; //get age when component created
    this.gender = this.$store.getters.getGender;
    const cardioObject = this.$store.getters.getCardioData;

    if (cardioObject) {
      //if not null
      this.cardioData = cardioObject;
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.dispatch("saveCardioData", this.cardioData); //save details upon leaving page
    next();
  },
};
</script>

<style scoped>

.customQuestion {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>